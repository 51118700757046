import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { ModuleWithProviders, Component } from '@angular/core';

import { PagesComponent } from './pages/pages.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { LockScreenComponent } from './pages/lock-screen/lock-screen.component';
import { ResetpasswordComponent } from './pages/resetpassword/resetpassword.component';
import { DemographicsComponent } from './pages/tools/demographics/demographics.component';

export const routes: Routes = [
    {
        path: '',
        component: PagesComponent, children: [
            { path: '',
                loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule)
            },
            {
                path: 'about',
                loadChildren: () => import('./pages/about/about.module').then(m => m.AboutModule)
            },
            {
                path: 'news',
                loadChildren: () => import('./pages/news/news.module').then(m => m.NewsModule)
            },
            {
                path: 'contact',
                loadChildren: () => import('./pages/contact/contact.module').then(m => m.ContactModule)
            },
            {
                path: 'properties',
                loadChildren: () => import('./pages/properties/properties.module').then(m => m.PropertiesModule)
            },
            {
                path: 'agents',
                loadChildren: () => import('./pages/agents/agents.module').then(m => m.AgentsModule)
            },
            {
                path: 'compare',
                loadChildren: () => import('./pages/compare/compare.module').then(m => m.CompareModule)
            },
            {
                path: 'pricing',
                loadChildren: () => import('./pages/pricing/pricing.module').then(m => m.PricingModule)
            },
            {
                path: 'faq',
                loadChildren: () => import('./pages/faq/faq.module').then(m => m.FaqModule)
            },
            {
                path: 'login',
                loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule)
            },
            {
                path: 'register',
                loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterModule)
            },
            {
                path: 'terms-conditions',
                loadChildren: () => import('./pages/terms-conditions/terms-conditions.module').then(m => m.TermsConditionsModule)
            },
            {
                path: 'account',
                loadChildren: () => import('./pages/account/account.module').then(m => m.AccountModule)
            },
            {
                path: 'submit-property',
                loadChildren: () => import('./pages/submit-property/submit-property.module').then(m => m.SubmitPropertyModule)
            },
            {
                path: 'credit-card',
                loadChildren: () => import('./pages/credit-card-details/credit-card-details.module').then(m => m.CreditCardDetailsModule)
            },
            {
                path: 'admin',
                loadChildren: () => import('./pages/admin/admin.module').then(m => m.AdminModule)
            },
            {
                path: 'mortgage',
                loadChildren: () => import('./pages/tools/mortgage-calculator/mortgage-module').then(m => m.mortgageModule)
            },
            {
                path: 'demographics',
                component: DemographicsComponent
            },
            {
                path: 'property-records',
                loadChildren: () => import('./pages/tools/property-records/property-records.module').then(m => m.PropertyRecordsModule)
            },
            {
                path: 'property-records-details',
                loadChildren: () => import('./pages/tools/property-records/property-records-details/property-records-details.module').then(m => m.PropertyRecordsDetailsModule)
            },
            {
                path: 'traffic-count',
                loadChildren: () => import('./pages/tools/traffic-count/traffic-count.module').then(m => m.TrafficCountModule)
            },
            {
                path: 'date-wheel',
                loadChildren: () => import('./pages/tools/date-wheel/date-wheel.module').then(m => m.DateWheelModule)
            },
            {
                path: 'comps-tab',
                loadChildren: () => import('./pages/tools/comps-tab/comps-tab.module').then(m => m.CompsTabModule)
            },
            {
                path: 'comp-detail',
                loadChildren: () => import('./pages/comp-details/comp-details.module').then(m => m.CompDetailsModule)
            },
            {
                path: 'mapping',
                loadChildren: () => import('./pages/tools/mapping-tab/mapping-tab.module').then(m => m.MappingTabModule)
            },
            { path: 'tool-details',
            loadChildren: () => import('./pages/Tool-details/tool-details/tool-details.module').then(m => m.ToolDetailsModule)
            },
            
            
        ]
    },
    { path: 'landing', loadChildren: () => import('./pages/landing/landing.module').then(m => m.LandingModule) },
    { path: 'lock-screen', component: LockScreenComponent },
    { path: '**', component: NotFoundComponent }
];

export const AppRouting: ModuleWithProviders = RouterModule.forRoot(routes, {
    // preloadingStrategy: PreloadAllModules 
     // <- comment this line for activate lazy load
});