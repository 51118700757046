import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges, AfterViewInit, ElementRef, ViewChild, NgZone } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppService } from '../../app.service';
import { Router, NavigationEnd } from '@angular/router';
import { PagesService } from '../../pages/pages.service';
import { MatDialog } from '@angular/material/dialog';
import { MapsAPILoader } from '@agm/core';
import { AdvancedSearchComponent } from './advanced-search/advanced-search.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { async } from '@angular/core/testing';
declare const google: any;

@Component({
    selector: 'app-properties-search',
    templateUrl: './properties-search.component.html',
    styleUrls: ['./properties-search.component.scss']
})
export class PropertiesSearchComponent implements OnInit, OnChanges {
    @ViewChild('addressAutocomplete') addressAutocomplete: ElementRef;
    @Input() variant: number = 1;
    @Input() vertical: boolean = false;
    @Input() searchOnBtnClick: boolean = false;
    @Input() removedSearchField: string;
    @Input() onHome: boolean;
    @Input() onProperty: boolean;
    @Input() activeTab: string;

    @Output() onSearchChange: EventEmitter<any> = new EventEmitter<any>();
    @Output() onSearchClick: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild('addressLocation', { static: false }) public addressLocation: ElementRef;
    startAutocomplete;
    newAddress: any;
    location;
    addressComplete = false;
    propertyName;
    public showMore: boolean = false;
    public form: FormGroup;
    public propertyTypes = [];
    public propertyCategory = [];
    public propertyStatuses = [];
    public cities = [];
    public neighborhoods = [];
    panelOpenState = false;
    public propertyType = []
    public streets = [];
    public features = [];
    public Healthcare: Array<string> = []
    healthcareArray: any[] = [];
    public completeAddress = ''
    public lat: number = 40.678178;
    public lng: number = -73.944158;
    constructor(
        public appService: AppService,
        private mapsAPILoader: MapsAPILoader,
        private ngZone: NgZone,
        public fb: FormBuilder,
        public dialog: MatDialog,
        public router: Router,
        public pagesService: PagesService,
        public snackBar: MatSnackBar) { }

    //for showing property types selected !!!
    selectedTypes = [];
    selectedItems = function (name, selected) {
        if (selected == true) {
            this.selectedTypes.push(name);
        }
        else if (selected == false) {
            var index = this.selectedTypes.indexOf(name)
            this.selectedTypes.splice(index, 1)
        }
        //remove duplicates
        this.selectedTypes = this.selectedTypes.filter((value, index) => this.selectedTypes.indexOf(value) === index)
        this.selectedTypes = this.selectedTypes.filter(function (element) { return element !== undefined; });
    };

    //fucntion to remove selected type and uncheck the box
    visible = true;
    removable = true;
    remove(type) {
        //set checkbox value to false
        this.form.value.Healthcare.map((item) => {
            if (item.name == type && item.selected === true) {
                item.selected = false;
            }
        }
        )

        //remove from array
        var index = this.selectedTypes.indexOf(type)
        this.selectedTypes.splice(index, 1)
    }

    ngOnInit() {
        if (this.vertical) {
            this.showMore = true;
        };
        this.propertyCategory = this.appService.getPropertyTypes();
        this.cities = this.appService.getCities();
        this.neighborhoods = this.appService.getNeighborhoods();
        this.streets = this.appService.getStreets();
        this.features = this.appService.getFeatures();
        this.getHealthCareData()
        this.form = this.fb.group({
            completeAddress: '',
            propertyName: '',
            Healthcare: '',
            propertyCategory: null,
            propertyType: null,
            propertyStatus: null,
            price: this.fb.group({
                from: null,
                to: null
            }),
            address: this.fb.group({
                completeAddress: [''],
                location: '',
                city: '',
                zipCode: '',
                neighborhood: '',
                street: '',
                country: '',
                state: '',
            }),
            city: null,
            zipCode: null,
            neighborhood: null,
            street: null,
            bedrooms: this.fb.group({
                from: null,
                to: null
            }),
            bathrooms: this.fb.group({
                from: null,
                to: null
            }),
            garages: this.fb.group({
                from: null,
                to: null
            }),
            area: this.fb.group({
                from: null,
                to: null
            }),
            yearBuilt: this.fb.group({
                from: null,
                to: null
            }),
            features: this.buildFeatures()
        });
        this.onSearchChange.emit(this.form);

    }
    public onSelectionChange(data) {

    }

    subOptions(event) {
        event.stopPropagation();

    }
    ngAfterViewInit() {
        this.placesAutocomplete();
    }
    public properties() {

        this.submitSearchForm()
    }
    public buildFeatures() {
        const arr = this.features.map(feature => {
            return this.fb.group({
                id: feature.id,
                name: feature.name,
                selected: feature.selected
            });
        })
        return this.fb.array(arr);
    }

    private placesAutocomplete() {
        this.mapsAPILoader.load().then(() => {
            var options = {
                types: ['(regions)'],
                componentRestrictions: {country: "us"}
            };
            let autocomplete = new google.maps.places.Autocomplete(this.addressAutocomplete.nativeElement, options);

            autocomplete.addListener("place_changed", () => {
                this.ngZone.run(async () => {
                    let place: google.maps.places.PlaceResult = autocomplete.getPlace();
                    this.completeAddress = place.formatted_address
                    this.form.controls.address.get('completeAddress').setValue(place.formatted_address)
                    this.form.controls.address.get('location').setValue(this.location)
                    if (place.geometry === undefined || place.geometry === null) {
                        return;
                    };
                    this.lat = place.geometry.location.lat();
                    this.lng = place.geometry.location.lng();
                    await this.getAddress();
                });
            });
        });
    }
    public getAddress() {
        this.appService.getAddress(this.lat, this.lng).subscribe(response => {
            let address = response['results'][0].formatted_address;
            this.completeAddress = address
            this.form.controls.address.get('completeAddress').setValue(address);
            this.setAddresses(response['results'][0]);
        })
    }

    public setAddresses(result) {
        this.form.controls.address.get('city').setValue(null);
        this.form.controls.address.get('zipCode').setValue(null);
        this.form.controls.address.get('street').setValue(null);

        var newCity, newStreet, newNeighborhood, newState;

        result.address_components.forEach(item => {
            if (item.types.indexOf('locality') > -1) {
                if (this.cities.filter(city => city.name == item.long_name)[0]) {
                    newCity = this.cities.filter(city => city.name == item.long_name)[0];
                }
                else {
                    newCity = { id: this.cities.length + 1, name: item.long_name };
                    this.cities.push(newCity);
                }
                this.form.controls.address.get('city').setValue(newCity.name);
            }
            if (item.types.indexOf('postal_code') > -1) {
                this.form.controls.address.get('zipCode').setValue(item.long_name);
            }

            if (item.types.indexOf('country') > -1) {
                this.form.controls.address.get('country').setValue(item.long_name);
            }
            if (item.types.indexOf('administrative_area_level_1') > -1) {

                if (this.cities.filter(city => city.name == item.long_name)[0]) {
                    newState = this.cities.filter(city => city.name == item.long_name)[0];
                }
                else {
                    newState = { id: this.cities.length + 1, name: item.long_name };
                    this.cities.push(newState);
                }
                this.form.controls.address.get('state').setValue(newState.name);
            }
        });

        if (!newCity) {
            result.address_components.forEach(item => {
                if (item.types.indexOf('administrative_area_level_1') > -1) {
                    if (this.cities.filter(city => city.name == item.long_name)[0]) {
                        newCity = this.cities.filter(city => city.name == item.long_name)[0];
                    }
                    else {
                        newCity = {
                            id: this.cities.length + 1,
                            name: item.long_name
                        };
                        this.cities.push(newCity);
                    }
                    this.form.controls.address.get('city').setValue(newCity.name);
                }
            });
        }
        if (newCity) {
            result.address_components.forEach(item => {
                if (item.types.indexOf('neighborhood') > -1) {
                    let neighborhood = this.neighborhoods.filter(n => n.name == item.long_name && n.cityId == newCity.id)[0];
                    if (neighborhood) {
                        newNeighborhood = neighborhood;
                    }
                    else {
                        newNeighborhood = {
                            id: this.neighborhoods.length + 1,
                            name: item.long_name,
                            cityId: newCity.id
                        };
                        this.neighborhoods.push(newNeighborhood);
                    }
                    this.neighborhoods = [...this.neighborhoods];
                    this.form.controls.address.get('neighborhood').setValue(newNeighborhood.name);
                }
            })
        }

        if (newCity) {
            result.address_components.forEach(item => {
                if (item.types.indexOf('route') > -1) {
                    if (this.streets.filter(street => street.name == item.long_name && street.cityId == newCity.id)[0]) {
                        newStreet = this.streets.filter(street => street.name == item.long_name && street.cityId == newCity.id)[0];
                    }
                    else {
                        newStreet = {
                            id: this.streets.length + 1,
                            name: item.long_name,
                            cityId: newCity.id,
                            neighborhoodId: (newNeighborhood) ? newNeighborhood.id : null
                        };
                        this.streets.push(newStreet);
                    }
                    this.streets = [...this.streets];
                    this.form.controls.address.get('street').setValue(newStreet.name);
                }
            })
        }
    }
    ngOnChanges(changes: SimpleChanges) {
        if (changes.activeTab) {
            if (this.activeTab === 'Sale') {
                this.propertyTypes = this.appService.getForsale();
            } else if (this.activeTab === 'lease') {
                this.propertyTypes = this.appService.getForlease();
            } else if (this.activeTab === 'Buisseness') {
                this.propertyTypes = this.appService.getForbusiness();
            }
        }

        if (this.removedSearchField) {
            if (this.removedSearchField.indexOf(".") > -1) {
                let arr = this.removedSearchField.split(".");
                this.form.controls[arr[0]]['controls'][arr[1]].reset();
            }
            else if (this.removedSearchField.indexOf(",") > -1) {
                let arr = this.removedSearchField.split(",");
                this.form.controls[arr[0]]['controls'][arr[1]]['controls']['selected'].setValue(false);
            }
            else {
                this.form.controls[this.removedSearchField].reset();
            }
        }
    }
    panelClick(o, t) {
    }
    openAdvancedSearchDialog() {
        const dialogRef = this.dialog.open(AdvancedSearchComponent, {
            width: '100%',
            height: '620px;',
            panelClass: 'my-dialog-container'

        });
        dialogRef.afterClosed().subscribe(result => {
            if (result && result.data) {
                this.pagesService.StorePropertyData(result.data, result.searchSendData)
                setTimeout(() => {
                    this.router.navigate(['/properties']);
                }, 2000);
            }
            else {
            }
        });
    }

    submitSearchForm() {
        if (this.form.invalid) {
            this.snackBar.open("Please enter Location", '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
            return false
        }

        // this.form.value.Healthcare.map((item) => {
        //     if (item.selected === true) {
        //         this.healthcareArray.push(item.name)
        //     }
        // }
        // )

        let states = []
        let city = []
        const healthcare = {
            name: this.form.value.Healthcare,
            selected: true
        }
        states.push(this.form.value.address.state);
        city.push(this.form.value.address.city);
        let sendData = {
            propertyName: this.form.value.propertyName,
            // propertyCategory: this.activeTab,
            propertyType: healthcare,
            resultsPerPage: 12,
            country: this.form.value.address.country,
            states: states,
            city: city,
            // zipCode: parseInt(this.form.value.address.zipCode),
            // street: this.form.value.address.street,
        }
        this.pagesService.userAdvancedSearch(sendData).then((res: any) => {
            if (res.success && res.property.length > 0) {
                this.pagesService.StorePropertyData(res, sendData)
                this.snackBar.open('Properties Found', '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
                this.router.navigate(['/properties']);
            } else {
                this.snackBar.open(res.message, '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
            }
        })
    }
    // public buildHealthcare() {
    //     const arr = this.Healthcare.map(t => {
    //         return this.fb.group({
    //             name: t.name,
    //             selected: t.selected
    //         });
    //     })
    //     return this.fb.array(arr);
    // }

    public reset() {
        this.form.reset({
            propertyType: null,
            propertyStatus: null,
            price: {
                from: null,
                to: null
            },
            city: null,
            zipCode: null,
            neighborhood: null,
            street: null,
            bedrooms: {
                from: null,
                to: null
            },
            bathrooms: {
                from: null,
                to: null
            },
            garages: {
                from: null,
                to: null
            },
            area: {
                from: null,
                to: null
            },
            yearBuilt: {
                from: null,
                to: null
            },
            features: this.features
        });
    }

    public search() {
        this.onSearchClick.emit();
    }

    public onSelectCity() {
        this.form.controls['neighborhood'].setValue(null, { emitEvent: false });
        this.form.controls['street'].setValue(null, { emitEvent: false });
    }
    public onSelectNeighborhood() {
        this.form.controls['street'].setValue(null, { emitEvent: false });
    }

    public getAppearance() {
        return (this.variant != 3) ? 'outline' : '';
    }
    public getFloatLabel() {
        return (this.variant == 1) ? 'always' : '';
    }

    // function to get customer address
    initAutocompletes() {
        // Create the autocomplete object, restricting the search to geographical
        // location types.
        const options = {
            types: [],
            componentRestrictions: { country: 'us' }
        };
        this.startAutocomplete = new google.maps.places.Autocomplete(
        /** @type {!HTMLInputElement} */ this.addressLocation.nativeElement,
            options
        );
        setTimeout(() => {
            if (this.startAutocomplete) {
                this.startAutocomplete.setFields('address_components');
            }
        }, 50);
        // When the user selects an address from the dropdown, populate the address
        // fields in the form.
        this.startAutocomplete.addListener('place_changed', () => {
            // Get the place details from the autocomplete object.
            this.location = this.startAutocomplete.getPlace();
            this.newAddress = this.location.formatted_address;
            this.addressComplete = false;
        });
    }

    changeAddress() {
        setTimeout(() => {
            if (this.location && this.location.address_components) {
                this.location.address_components.map(item => {
                    item.types.map(type => {
                        // if (type === 'locality') {
                        //   this.customer.city = item.long_name;
                        // }
                        // if (type === 'administrative_area_level_1') {
                        //   this.customer.state = item.long_name;
                        // }
                        // if (type === 'country') {
                        //   this.customer.country = item.long_name;
                        // }
                        // if (type === 'postal_code') {
                        //   this.customer.zip_code = item.long_name;
                        // }
                    });
                });
            } else {
                this.changeAddress();
            }
        }, 500);
    }

    /**
     * get HealthCare data 
     */
    getHealthCareData() {
        this.Healthcare.push(
            'Land',
            'Flex',
            'Office',
            'Healthcare',
            'ShoppingCenter',
            'Specialty',
            'Restaurant',
            'Coworking',
            'Medical',
            'Retail',
            'Industrial',
            'MultiFamily',
            'Hospitality',
            'Restaurant And Food',
            'Automotive And Marine',
            'Building And Construction',
            'Online And Technology',
            'Service Industry',
            'Transportation Storage',
            'Wholesale And Distributers'
        )
    }

}
