import { Component, OnInit, Input } from '@angular/core';
import { MenuService } from '../menu.service';
import { Menu } from '../menu.model';
import { PagesService } from '../../../../pages/pages.service';

@Component({
  selector: 'app-horizontal-menu',
  templateUrl: './horizontal-menu.component.html',
  styleUrls: ['./horizontal-menu.component.scss'],
  providers: [ MenuService ]
})
export class HorizontalMenuComponent implements OnInit {
   loggedIn = false;
   userType = '';
  @Input('menuParentId') menuParentId;
  public menuItems: Array<Menu>;
  public userItem: Array<Menu>;
  public subscriberItem: Array<Menu>;
  public subscriberItem2: Array<Menu>;
  public subscriberItem1: Array<Menu>;
  public adminItem: Array<Menu>;
  constructor(public menuService:MenuService , public pagesServices:PagesService) {}

  ngOnInit() {
    this.menuItems = this.menuService.getHorizontalMenuItems();
    this.userItem = this.menuService.getuserMenu();
    this.subscriberItem = this.menuService.getsubscriberMenu();
    this.subscriberItem2 = this.menuService.subscriberMenuCreditCard();
    this.subscriberItem1 = this.menuService.subscriberMenuPlans();
    this.adminItem = this.menuService.getAdminMenu();
    this.menuItems = this.menuItems.filter(item => item.parentId === this.menuParentId);
    this.pagesServices.currentMessage.subscribe(item => {
      setTimeout(() => {
        if(!localStorage.getItem('loggedIn')) {
          this.loggedIn = false;
        } else {
          this.loggedIn = true;
        }
      }, 200);
    });
    this.pagesServices.currentUser.subscribe(item => {
      this.userType = item;
    });
    this.userType = localStorage.getItem('subscriberdetail');
    this.userType = sessionStorage.getItem('userType') || localStorage.getItem('userType');
    let logIn = localStorage.getItem('loggedIn')
    if (logIn && logIn == "true"){
      this.loggedIn = true
    }
  }

}
