import { Component, OnInit, ViewChild, ElementRef, NgZone, Input } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { AppService, } from 'src/app/app.service';
import { Router } from '@angular/router';
import { PagesService } from '../../pages.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MapsAPILoader } from '@agm/core';

@Component({
    selector: 'app-property-records',
    templateUrl: './property-records.component.html',
    styleUrls: ['./property-records.component.scss']
})
export class PropertyRecordsComponent implements OnInit {

    @ViewChild('addressAutocomplete') addressAutocomplete: ElementRef;
    startAutocomplete;
    newAddress: any;
    addressComplete = false;
    public form: FormGroup;
    dialogRef: any;
    apn: number;
    fips: number;
    lat: number;
    isValidCountry = true;
    long: number;
    address1: string;
    address2: string;
    constructor(
        public appService: AppService,
        public fb: FormBuilder,
        public dialog: MatDialog,
        public router: Router,
        public pagesService: PagesService,
        public snackBar: MatSnackBar,
        private mapsAPILoader: MapsAPILoader,
        private ngZone: NgZone
        ) {
    }

    ngOnInit() {
        // Address 
        this.form = this.fb.group({
            address1: [''],
            apn: ['', Validators.compose([Validators.pattern['/^[a-zA-Z0-9]+$/']])],
            fips: ['', Validators.compose([Validators.pattern['/^[a-zA-Z0-9]+$/']])],
            lat: '',
            long: '',
            address: this.fb.group({
                address1: [''],
            }),
        })
        this.setConditionValidation();
    }

    setConditionValidation() {
        const address1 = this.form.get('address1')
        const apn = this.form.get('apn')
        const fips = this.form.get('fips')
        this.form.valueChanges.subscribe(form => {
            if (address1) {
                this.form.get('apn').setValidators(null);
                this.form.get('fips').setValidators(null);
            } else {
                this.form.get('apn').setValidators(Validators.required);
                this.form.get('fips').setValidators(Validators.required);
            }
            if (apn && fips) {
                this.form.get('address1').setValidators(null);
            } else {
                this.form.get('address1').setValidators(Validators.required);
            }
        })
    }

    ngAfterViewInit() {
        this.placesAutocomplete();
    }
    private placesAutocomplete() {
        this.mapsAPILoader.load().then(() => {
            let autocomplete = new google.maps.places.Autocomplete(this.addressAutocomplete.nativeElement, {
                types: ["address"]
            });
            google.maps.event.addListener(autocomplete, "place_changed", () => {
                this.ngZone.run(() => {
                    let place: google.maps.places.PlaceResult = autocomplete.getPlace();
                    this.form.controls.address.get('address1').setValue(place.formatted_address)
                    if (place.geometry === undefined || place.geometry === null) {
                        return;
                    };
                    this.lat = place.geometry.location.lat();
                    this.long = place.geometry.location.lng();
                    //this.getAddress();
                });
            });
        });
    }

    // public getAddress() {
    //     this.appService.getAddress(this.lat, this.long).subscribe(response => {
    //         this.isValidCountry = false
    //         let addres = response['results'][0].address_components;
    //         let address = response['results'][0].formatted_address;
    //         let addres1 = '';
    //         let addres2 = '';
    //         addres.forEach((add) => {
    //             if (add && add.types && add.types[0] == "street_number") addres2 = add.long_name;
    //             if (add && add.types && add.types[0] == "route") addres2 += ' ' + add.long_name;

    //             if (add && add.types && add.types[0] == "locality") addres1 += ', ' + add.long_name;
    //             if (add && add.types && add.types[0] == "administrative_area_level_1") addres1 = add.short_name;
    //             if(add && add.types && (add.types[0] == "country" || add.types[0] == "political") && (add.short_name == 'US' || add.long_name == 'United States')){
    //                 this.isValidCountry = true;
    //             }
    //         })
            
    //         this.address1 = addres1;
    //         this.address2 = addres2;
    //         this.form.controls.address.get('address1').setValue(address);
    //     })
    // }

    searchProperties() {
        if(this.isValidCountry){
            const sendData = {
                address1: this.lat,
                address2: this.long,
                apn: this.form.value.apn,
                fips: this.form.value.fips
            };
            console.log(sendData)
            this.pagesService.userPropertyRecords(sendData).then((res: any) => {
                this.appService.sendPropertyDetails(res);
    
                if (this.form.controls.address1.value || this.form.controls.apn.value) {
                    let data = {
                        lat: this.lat,
                        long: this.long
                    }
                    if (res.success && res.FinalArray && res.FinalArray.lat && res.FinalArray.long) {
                        data = {
                            lat: res.FinalArray.lat,
                            long: res.FinalArray.long
                        }
                    }
                    this.appService.propertyRecords = data;
                    // this.router.navigate(['/property-records-details']);
                    if (res.success &&  res?.FinalArray) {
                        this.router.navigate(['/property-records-details']);
                    } else {
                        this.snackBar.open('Property Not Found', '×', { panelClass: 'success', verticalPosition: 'top', duration: 1500 });
                    }
                } else {
                    this.snackBar.open('Please Enter Either Address Or Other Fields', '×',
                    { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
                    return false;
                }
            });
            this.address1 = '';
            this.address2 = '';
        }else{
            this.snackBar.open('We only provide property records data for United States', '×', { panelClass: 'success', verticalPosition: 'top', duration: 1500 });
        }
    }
    close(responseData, sendDataNew) {
        this.dialogRef.close({ data: responseData, searchSendData: sendDataNew });
    }

    reloadComponent() {
        let currentUrl = this.router.url;
            this.router.routeReuseStrategy.shouldReuseRoute = () => false;
            this.router.onSameUrlNavigation = 'reload';
            this.router.navigate([currentUrl]);
        }
}


