import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { AppService } from 'src/app/app.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PagesService } from '../../../../app/pages/pages.service';
import {COMMA, ENTER, BACKSPACE} from '@angular/cdk/keycodes';
import {MatChipInputEvent} from '@angular/material/chips';

interface property {
  value: string;
}
interface PropertyTypeGroups {
  disabled?: boolean;
  name: string;
  key: string;
  propertyType: property[];
}
@Component({
  selector: 'app-advanced-search',
  templateUrl: './advanced-search.component.html',
  styleUrls: ['./advanced-search.component.scss']
})
export class AdvancedSearchComponent implements OnInit {
  activeTab = '';
  public unitArray = ['Total S','S/SF','S/Acre','S/Unit'];
  public sfArray = ['SF','SM']
  public acresArray = ['Acres' , 'SF']
  public TimePeriodArray = ['Time Period','Custom Time Period']
  propertyTypeControl = new FormControl();
  // propertyTypeGroups: PropertyTypeGroups[] = [
  //   {
  //     name: 'Office',
  //     propertyType: [
  //       {value: 'Industrial Live/Work'},
  //       {value: 'Medical'},
  //       {value: 'Office/Storefront Retail'},
  //       {value: 'Office Live/Work Unit'},
  //       {value: 'Office/Residential'},
  //       {value: 'Telecom Hotel/Data'},
  //     ]
  //   },
  //   {
  //     name: 'Healthcare',
  //     propertyType: [
  //       {value: 'Industrial Live/Work'},
  //       {value: 'Loft/Creative Space'},
  //       {value: 'Medical'},
  //       {value: 'Office/Storefront Retail'},
  //       {value: 'Office Live/Work Unit'},
  //       {value: 'Office/Residential'},
  //       {value: 'Telecom Hotel/Data'},
  //     ]
  //   },
  //   {
  //     name: 'Hospitality',
  //     // disabled: true,
  //     propertyType: [
  //       {value: 'Hotel'},
  //       {value: 'Hotel Casino'},
  //       {value: 'Motel'},
  //       {value: 'Single Room Occupancy'}
  //     ]
  //   },
  //   {
  //     name: 'Land',
  //     propertyType: [
  //       {value: 'Residential/Multifamily'},
  //       {value: 'Commercial'},
  //       {value: 'Industrial'},
  //       {value: 'Agricultural'}
  //     ]
  //   },
  //   {
  //     name: 'Multifamily',
  //     propertyType: [
  //       {value: 'Garden'},
  //       {value: 'Low-Rise'},
  //       {value: 'Mid-Rise'},
  //       {value: 'High-Rise'},
  //       {value: 'Dormitory'},
  //       {value: 'Manufactured Housing'},
  //     ]
  //   },
  //   {
  //     name: 'Retail',
  //     propertyType: [
  //       {value: 'Bank'},
  //       {value: 'Daycare Facility/Nursery'},
  //       {value: 'Drug Store'},
  //       {value: 'Freestanding'},
  //       {value: 'Grocery/Convenience Store'},
  //       {value: 'Service/Gas Station'},
  //       {value: 'Showroom'},
  //       {value: 'Storefront'},
  //       {value: 'Storefront Retail/Office'},
  //       {value: 'Storefront Retail/Residential'},
  //       {value: 'Other Retail'},
  //     ]
  //   },
  //   {
  //     name: 'Restaurant',
  //     propertyType: [
  //       {value: 'Restaurant'}
  //     ]
  //   },
  //   {
  //     name: 'Shopping Center',
  //     propertyType: [
  //       {value: 'Airport Retail'},
  //       {value: 'Community Center'},
  //       {value: 'Lifestyle Center'},
  //       {value: 'Neighborhood Center'},
  //       {value: 'Outlet Center'},
  //       {value: 'Power Center'},
  //       {value: 'Regional Mall'},
  //       {value: 'Theme/Festival Center'},
  //       {value: 'Strip Center'},
  //       {value: 'Super Regional Mall'},
  //     ]
  //   },
  //   {
  //     name: 'Specialty',
  //     propertyType: [
  //       {value: 'Car Wash'},
  //       {value: 'Marina'},
  //       {value: 'Religious Facility'},
  //       {value: 'Self Storage'},
  //       {value: 'Schools'},
  //       {value: 'Parking'},
  //       {value: 'Other Specialty'},
  //     ]
  //   },
  // ];
  propertyTypeSale: PropertyTypeGroups[] = [
    {
      name: 'Office',
      key: 'office',
      propertyType: [
          { value: 'Industrial Live/Work'},
          { value: 'Loft/Creative Space'},
          { value: 'Medical'},
          { value: 'Office/Storefront Retail'},
          { value: 'Office Live/Work Unit'},
          { value: 'Office/Residential'},
          { value: 'Telecom Hotel/Data'},
      ]
    },
    {
      name: 'Healthcare',
      key: 'healthcare',
      propertyType: [
          { value: 'Industrial Live/Work'},
          { value: 'Loft/Creative Space'},
          { value: 'Medical'},
          { value: 'Office/Storefront Retail'},
          { value: 'Office Live/Work Unit'},
          { value: 'Office/Residential'},
          { value: 'Telecom Hotel/Data'},
      ]
    },
    {
      name: 'Hospitality',
      key: 'hospitality',
      // disabled: true,
      propertyType: [
          { value: 'Hotel'},
          { value: 'Hotel Casino'},
          { value: 'Motel'},
          { value: 'Single Room Occupancy'},
      ]
    },
    {
      name: 'Land',
      key: 'land',
      propertyType: [
          { value: 'Residential/Multifamily'},
          { value: 'Commercial'},
          { value: 'Industrial'},
          { value: 'Agricultural'},
      ]
    },
    {
      name: 'Multifamily',
      key: 'multifamily',
      propertyType: [
          { value: 'Garden'},
          { value: 'Low-Rise'},
          { value: 'Mid-Rise'},
          { value: 'High-Rise'},
          { value: 'Dormitory'},
          { value: 'Manufactured Housing'},
      ]
    },
    {
      name: 'Retail',
      key: 'retail',
      propertyType: [
          { value: 'Bank'},
          { value: 'Daycare Facility/Nursery'},
          { value: 'Drug Store'},
          { value: 'Freestanding'},
          { value: 'Grocery/Convenience Store'},
          { value: 'Service/Gas Station'},
          { value: 'Showroom'},
          { value: 'Storefront'},
          { value: 'Storefront Retail/Office'},
          { value: 'Storefront Retail/Residential'},
          { value: 'Other Retail'},
      ]
    },
    {
      name: 'Restaurant',
      key: 'restaurant',
      propertyType: [
          { value: 'Restaurant'}
      ]
    },
    {
      name: 'Shopping Center',
      key: 'shopping_Center',
      propertyType: [
          { value: 'Community Center'},
          { value: 'Lifestyle Center'},
          { value: 'Neighborhood Center'},
          { value: 'Outlet Center'},
          { value: 'Power Center'},
          { value: 'Regional Mall'},
          { value: 'Theme/Festival Center'},
          { value: 'Strip Center'},
          { value: 'Super Regional Mall'},
      ]
    },
    {
      name: 'Specialty',
      key: 'speciality',
      propertyType: [
          { value: 'Marina'},
          { value: 'Religious Facility'},
          { value: 'Self Storage'},
          { value: 'Schools'},
          { value: 'Parking'},
          { value: 'Other Specialty'},
      ]
    },
    
    
    
  ];
  propertyTypeLease: PropertyTypeGroups[] = [
      {
          name: 'Office',
          key: 'office',
          propertyType: [
              { value: 'Industrial Live/Work'},
              { value: 'Loft/Creative Space'},
              { value: 'Medical'},
              { value: 'Office/Storefront Retail'},
              { value: 'Office Live/Work Unit'},
              { value: 'Office/Residential'},
              { value: 'Telecom Hotel/Data'},
          ]
      },
      {
          name: 'Flex',
          key: 'flex',
          propertyType: [
              { value: 'Flex'}
          ]
      },
      {
          name: 'Coworking',
          key: 'coworking',
          propertyType: [
              { value: 'Coworking'}
          ]
      },
      {
         name: 'Industrial',
         key: 'industrial',
         propertyType: [
          { value: 'Bank'},
          { value: 'Flex'},
          { value: 'Distribution'},
          { value: 'Manufacturing'},
          { value: 'R&D'},
          { value: 'Refrigeration/Cold Storage'},
          { value: 'Service'},
          { value: 'Showroom'},
          { value: 'Truck Terminal'},
          { value: 'Warehouse'},
          { value: 'Other Industrial'},
         ] 
      },
      {
          name: 'Retail',
          key: 'retail',
          propertyType: [
              { value: 'Bank'},
              { value: 'Daycare Facility/Nursery'},
              { value: 'Drug Store'},
              { value: 'Freestanding'},
              { value: 'Grocery/Convenience Store'},
              { value: 'Service/Gas Station'},
              { value: 'Showroom'},
              { value: 'Storefront'},
              { value: 'Storefront Retail/Office'},
              { value: 'Storefront Retail/Residential'},
              { value: 'Other Retail'},
          ]
        },
        {
          name: 'Medical',
          key: 'medical',
          propertyType: [
              { value: 'Medical'}
          ]
        },
        {
          name: 'Restaurant',
          key: 'restaurant',
          propertyType: [
              { value: 'Restaurant'}
          ]
        },
        {
          name: 'Land',
          key: 'land',
          propertyType: [
              { value: 'Residential/Multifamily'},
              { value: 'Commercial'},
              { value: 'Industrial'},
              { value: 'Agricultural'},
          ]
        },
  ];
//   public officeType = [{ id: 1, name: 'Industrial Live/Work', selected: false },
//   { id: 2, name: 'Loft/Creative Space' , selected: false},
//   { id: 3, name: 'Medical', selected: false },
//   { id: 4, name: 'Office/Storefront Retail', selected: false },
//   { id: 5, name: 'Office Live/Work Unit', selected: false },
//   { id: 6, name: 'Office/Residential', selected: false },
//   { id: 7, name: 'Telecom Hotel/Data', selected: false },
// ]

// public Healthcare = [{  name: 'Industrial Live/Work', selected: false },
// {  name: 'Loft/Creative Space' , selected: false},
// {  name: 'Medical', selected: false },
// {  name: 'Office/Storefront Retail', selected: false },
// {  name: 'Office Live/Work Unit', selected: false },
// { name: 'Office/Residential', selected: false },
// {  name: 'Telecom Hotel/Data', selected: false },
// ]
// public shoppingCenter = [{ id: 1, name: 'Airport Retail' , selected: false},
// { id: 2, name: 'Community Center', selected: false },
// { id: 3, name: 'Lifestyle Center', selected: false },
// { id: 4, name: 'Neighborhood Center' , selected: false},
// { id: 5, name: 'Outlet Center' , selected: false},
// { id: 6, name: 'Power Center' , selected: false},
// { id: 7, name: 'Regional Mall' , selected: false},
// { id: 7, name: 'Theme/Festival Center' , selected: false},
// { id: 7, name: 'Strip Center' , selected: false},
// { id: 7, name: 'Super Regional Mall', selected: false },
// ]
// public Specialty = [{ id: 1, name: 'Car Wash' , selected: false},
// { id: 2, name: 'Marina' , selected: false},
// { id: 3, name: 'Religious Facility', selected: false },
// { id: 4, name: 'Self Storage', selected: false },
// { id: 5, name: 'Schools', selected: false },
// { id: 6, name: 'Parking', selected: false },
// { id: 7, name: 'Other Specialty' , selected: false},
// ]
// public Restaurant = [{ id: 1, name: 'Restaurant', selected: false },
// ]
// public Flex = [{ id: 1, name: 'Flex', selected: false },
// ]
// public Coworking = [{ id: 1, name: 'Coworking', selected: false },
// ]
// public Medical = [{ id: 1, name: 'Medical', selected: false },
// ] 
// public restaurantAndFood = [{ id: 1, name: 'restaurantAndFood', selected: false },
// ]   
// public automotiveAndMarine = [{ id: 1, name: 'Automotive and Marine', selected: false },
// ]   
// // public buildingAndConstruction = [{ id: 1, name: 'Building & Construction', selected: false },
// // ] 
// public onlineAndTechnology = [{ id: 1, name: 'Online & Technology', selected: false },
// ] 
// public serviceIndustry = [{ id: 1, name: 'Service Industry', selected: false },
// ] 
// public transportationStorage = [{ id: 1, name: 'Transportation & Storage', selected: false },
// ] 
// public wholesaleAndDistributers = [{ id: 1, name: 'Transportation & Storage', selected: false },
// ] 

// public Retail = [{ id: 1, name: 'Bank' , selected: false},
// { id: 2, name: 'Daycare Facility/Nursery' , selected: false},
// { id: 3, name: 'Drug Store' , selected: false},
// { id: 4, name: 'Freestanding' },
// { id: 5, name: 'Grocery/Convenience Store' , selected: false},
// { id: 6, name: 'Service/Gas Station', selected: false },
// { id: 7, name: 'Showroom' , selected: false},
// { id: 7, name: 'Storefront' , selected: false},
// { id: 7, name: 'Storefront Retail/Office' , selected: false},
// { id: 7, name: 'Storefront Retail/Residential' , selected: false},
// { id: 7, name: 'Other Retail' , selected: false},
// ]

// public Industrial = [{ id: 1, name: 'Bank' , selected: false},
// { id: 2, name: 'Flex' , selected: false},
// { id: 3, name: 'Distribution' , selected: false},
// { id: 4, name: 'Manufacturing' },
// { id: 5, name: 'R&D' , selected: false},
// { id: 6, name: 'Refrigeration/Cold Storage', selected: false },
// { id: 7, name: 'Service' , selected: false},
// { id: 7, name: 'Showroom' , selected: false},
// { id: 7, name: 'Truck Terminal' , selected: false},
// { id: 7, name: 'Warehouse' , selected: false},
// { id: 7, name: 'Other Industrial' , selected: false},
// ]
fruits: string[] = [];
states: string[] = [];
city: string[] = [];
// public multifamily = [{ id: 1, name: 'Garden', selected: false },
// { id: 2, name: 'Low-Rise' , selected: false},
// { id: 3, name: 'Mid-Rise', selected: false },
// { id: 4, name: 'High-Rise' , selected: false},
// { id: 5, name: 'Dormitory' , selected: false},
// { id: 6, name: 'Manufactured Housing', selected: false },
// ]
// public land = [{ id: 1, name: 'Residential/Multifamily' , selected: false},
// { id: 2, name: 'Commercial' , selected: false},
// { id: 3, name: 'Industrial' , selected: false},
// { id: 4, name: 'Agricultural' , selected: false},

// ]
public propertyStatus = [{ id: 1, name: 'Active' },{ id: 1, name: 'Inactive' }];
// public Hospitality = [{ id: 1, name: 'Hotel' , selected: false},
// { id: 2, name: 'Hotel Casino' , selected: false},
// { id: 3, name: 'Motel' , selected: false},
// { id: 4, name: 'Single Room Occupancy', selected: false },
// ]
public tenancyNew = [];
public tenancy = [
     {name: 'Single', selected: false }, 
     {name: 'Multiple', selected: false },
];
  separatorKeysCodes: number[] = [ENTER, COMMA];
  countryList: any[] = [];
  // officeNewArray : any[] = [];
  // landNewArray : any[] = [];
  // multifamilyNewArray : any[] = [];
  // retailNewArray : any[] = [];
  // shoppingCenterNewArray : any[] = [];
  // restaurantNewArray : any[] = [];
  // specialityNewArray : any[] = [];
  // flexNewArray : any[] = [];
  // coworkingNewArray : any[] = [];
  // industrialNewArray : any[] = [];
  // medicalNewArray : any[] = [];
  // randfNewArray : any[] = [];
  // aandmNewArray : any[] = [];
  // bandcNewArray : any[] = [];
  // serviceIndustryArray : any[] = [];
  // transportationStorageArray : any[] = [];
  // wholesaleAndDistributersArray : any[] = [];
  // onlineAndTechnologyArray  : any[] = [];
  // healthcareArray  : any[] = [];
  // hospitalityArray  : any[] = [];
  cities = []
  checkbox :any;
  // fruits: string[] = [];
  selectableState = true;
  removableState = true;
  selectableCity = true;
  removableCity = true;
  panelOpenState = false;
  advancedSearchForm: FormGroup;
  submitted: boolean = false;
  // automativeMarineArray: any[] = [];
  // buildingConstructionArray: any[] = [];
  constructor(public dialogRef: MatDialogRef<AdvancedSearchComponent>, public dialog: MatDialog, public appServices: AppService, public snackBar: MatSnackBar,
    // @Inject(MAT_DIALOG_DATA) public data: any,
    public pagesServices: PagesService,
    public fb: FormBuilder) { }

    //for showing property types selected !!!
    // selectedTypes = [];
    // selectedItems = function(name , selected) {
    //   if(selected==true)
    //   {
    //     this.selectedTypes.push(name);
    //   }
    //   else if(selected==false)
    //   {
    //     var index = this.selectedTypes.indexOf(name)
    //     this.selectedTypes.splice(index,1)
    //   }
    //    //remove duplicates
    //    this.selectedTypes = this.selectedTypes.filter((value,index) => this.selectedTypes.indexOf(value) === index)
    //    this.selectedTypes = this.selectedTypes.filter(function( element ) { return element !== undefined; });
    //   };

      //fucntion to remove selected type and uncheck the box
    // visible = true;
    // removable = true;
    // removeChip(type){
    //   //set checkbox value to false
    //   //office
    //   this.advancedSearchForm.value.officeType.map((item) => {
    //     if (item.name == type && item.selected === true) {
    //       item.selected = false;
    //     }
    //   });

    //   this.advancedSearchForm.value.land.map((item) => {
    //     if (item.name == type && item.selected === true) {
    //       item.selected=false;
    //       }
    //   }
    //   )
      
    //   this.advancedSearchForm.value.multifamily.map((item) => {
    //     if (item.name == type && item.selected === true) {
    //       item.selected=false;
    //       }
    //   }
    //   )
  
    //   this.advancedSearchForm.value.Retail.map((item) => {
    //     if (item.name == type && item.selected === true) {
    //       item.selected=false;
    //       }
    //   }
    //   )
  
    //   this.advancedSearchForm.value.Restaurant.map((item) => {
    //     if (item.name == type && item.selected === true) {
    //       item.selected=false;
    //       }
    //   }
    //   )
    //   this.advancedSearchForm.value.Specialty.map((item) => {
    //     if (item.name == type && item.selected === true) {
    //       item.selected=false;
    //       }
    //   }
    //   )
  
    //   this.advancedSearchForm.value.Flex.map((item) => {
    //     if (item.name == type && item.selected === true) {
    //       item.selected=false;
    //       }
    //   }
    //   )
    //   this.advancedSearchForm.value.shoppingCenter.map((item) => {
    //     if (item.name == type && item.selected === true) {
    //       item.selected=false;
    //       }
    //   }
    //   )
    //   this.advancedSearchForm.value.Coworking.map((item) => {
    //     if (item.name == type && item.selected === true) {
    //       item.selected=false;
    //       }
    //   }
    //   )
    //   this.advancedSearchForm.value.Industrial.map((item) => {
    //     if (item.name == type && item.selected === true) {
    //       item.selected=false;
    //       }
    //   }
    //   )
    //   this.advancedSearchForm.value.automotiveAndMarine.map((item) => {
    //     if (item.name == type && item.selected === true) {
    //       item.selected=false;
    //       }
    //   }
    //   )
    //   // this.advancedSearchForm.value.buildingAndConstruction.map((item) => {
    //   //   if (item.name == type && item.selected === true) {
    //   //     item.selected=false;
    //   //     }
    //   // }
    //   // )
    //   this.advancedSearchForm.value.serviceIndustry.map((item) => {
    //     if (item.name == type && item.selected === true) {
    //       item.selected=false;
    //       }
    //   }
    //   )
    //   this.advancedSearchForm.value.transportationStorage.map((item) => {
    //     if (item.name == type && item.selected === true) {
    //       item.selected=false;
    //       }
    //   }
    //   )
    //   this.advancedSearchForm.value.Retail.map((item) => {
    //     if (item.name == type && item.selected === true) {
    //       item.selected=false;
    //       }
    //   }
    //   )
    //   this.advancedSearchForm.value.onlineAndTechnology.map((item) => {
    //     if (item.name == type && item.selected === true) {
    //       item.selected=false;
    //       }
    //   }
    //   )
  
    //   this.advancedSearchForm.value.Healthcare.map((item) => {
    //     if (item.name == type && item.selected === true) {
    //       item.selected=false;
    //       }
    //   }
    //   )
  
    //   this.advancedSearchForm.value.Hospitality.map((item) => {
    //     if (item.name == type && item.selected === true) {
    //       item.selected=false;
    //       }
    //   }
    //   )
      
    //   //remove from array
    //   var index = this.selectedTypes.indexOf(type)
    //   this.selectedTypes.splice(index,1)
    // }

  ngOnInit(): void {
    this.activeTab = 'Sale';
    this.getCountryList();
    this.cities = this.appServices.getCities();
    this.advancedSearchForm = this.fb.group({
      // propertyCategory: ['', Validators.required],
      // propertyUse: ['', Validators.required],
      countryName: ['', Validators.required],
      city: '',
      // city: ['', Validators.required],
      // state: ['', Validators.required],
      state: '',
      buildingSize: '',
      metros: '',
      professionalSubmarket: '',
      zipCode: '',
      streetAddress : '',
      minPrize: '',
      maxPrize : '',
      buildingMinSize : '',
      buildingMaxSize : '',
      minRooms :'',
      maxRooms : '',
      minYearBuilt: '',
      maxYearBuilt : '',
      propertyStatus : '',
      // officeType: this.buildofficeType(),
      // Healthcare : this.buildHealthcare(),
      // land: this.buildlandType(),
      // multifamily: this.buildmultifamilyType(),
      // Retail: this.buildRetailType(),
      // Restaurant: this.buildRestaurantType(),
      // shoppingCenter: this.buildshoppingCenterType(),
      // Specialty: this.buildSpecialtyType(),
      // Flex: this.buildFlexType(),
      // Coworking : this.buildCoworkingType(),
      // Industrial : this.buildIndustrialType(),
      // Medical : this.buildMedicalType(),
      // restaurantAndFood : this.buildrestaurantAndFood(),
      // automotiveAndMarine : this.buildautomotiveAndMarine(),
      // buildingAndConstruction : this.buildbuildingAndConstruction(),
      // serviceIndustry : this.buildserviceIndustry(),
      // transportationStorage : this.buildtransportationStorage(),
      // wholesaleAndDistributers : this.buildwholesaleAndDistributerse(),
      // onlineAndTechnology : this.buildonlineAndTechnology(),
      // Hospitality : this.buildHospitality(),
      selected:false
    })
  // this.advancedSearchForm= this.fb.group({
  //   basic: this.fb.group({
  //     propertyCategory: [null, Validators.required],
  //     propertyType: [null],
  //     // propertyType: [null, Validators.required],
  //     propertyStatus: null, 
  //     gallery: null,
  //     officeType: this.buildofficeType(),
  //     land: this.buildlandType(),
  //     multifamily: this.buildmultifamilyType(),
  //     Retail: this.buildRetailType(),
  //     Restaurant: this.buildRestaurantType(),
  //     shoppingCenter: this.buildshoppingCenterType(),
  //     Specialty: this.buildSpecialtyType(),
  //     Flex: this.buildFlexType(),
  //     Coworking : this.buildCoworkingType(),
  //     Industrial : this.buildIndustrialType(),
  //     Medical : this.buildMedicalType(),
  //     restaurantAndFood : this.buildrestaurantAndFood(),
  //     automotiveAndMarine : this.buildautomotiveAndMarine(),
  //     buildingAndConstruction : this.buildbuildingAndConstruction(),
  //     serviceIndustry : this.buildserviceIndustry(),
  //     transportationStorage : this.buildtransportationStorage(),
  //     wholesaleAndDistributers : this.buildwholesaleAndDistributerse(),
  //     onlineAndTechnology : this.buildonlineAndTechnology(),
  //   }),
  //   address: this.fb.group({
  //     // location: ['', Validators.required],
  //     location: '',
  //     city: ['', Validators.required],
  //     zipCode: '',
  //     neighborhood: '',
  //     street: '',
  //     countryName:['', Validators.required],
  //     state : ['', Validators.required],
  //   }),
  //   additional: this.fb.group({
  //     bedrooms: '',
  //     bathrooms: '',
  //     garages: '',
  //     area: '',
  //     yearBuilt: '',
  //     buildingSize: '',
  //     metros: '',
  //     professionalSubmarket: '',
  //     tenancy : this.buildtenancy()
  //   }),

  // }); 
}

  setActive(tab) {
    this.activeTab = tab;
  }
  getCountryList() {
    this.appServices.getCountry().subscribe((res) => {
      this.countryList = res;
    });
  }
  close(responseData , sendDataNew){
    this.dialogRef.close({ data: responseData , searchSendData : sendDataNew});
  }
  subOptions(event){
    event.stopPropagation();

  }

  submitAdvancedSearchForm(){
    if(this.advancedSearchForm.invalid){
      this.snackBar.open("Please select Country", '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
      return false
    }
    let sendData_ = {}
    let _propertyType = {}
    const valueData = this.propertyTypeControl.value;
    if(valueData){
      _propertyType[valueData.key] = [valueData.value]
    }

  // if (this.officeNewArray.length > 0) {
  //     _propertyType['office'] = this.officeNewArray
  // }
  // if (this.flexNewArray.length > 0) {
  //     _propertyType['flex'] = this.flexNewArray
  // }
  // if (this.coworkingNewArray.length > 0) {
  //     _propertyType['coworking'] = this.coworkingNewArray
  // }
  // if (this.industrialNewArray.length > 0) {
  //     _propertyType['industrial'] = this.industrialNewArray
  // }
  // if (this.retailNewArray.length > 0) {
  //     _propertyType['retail'] = this.retailNewArray

  // }
  // if (this.restaurantNewArray.length > 0) {
  //     _propertyType['restaurant'] = this.restaurantNewArray
  // }
  // if (this.medicalNewArray.length > 0) {
  //     _propertyType['medical'] = this.medicalNewArray
  // }
  // if (this.healthcareArray.length > 0) {
  //     _propertyType['healthcare'] = this.healthcareArray
  // }
  // if (this.hospitalityArray.length > 0) {
  //     _propertyType['hospitality'] = this.hospitalityArray
  // }
  // if (this.landNewArray.length > 0) {
  //     _propertyType['land'] = this.landNewArray
  // }
  // if (this.multifamilyNewArray.length > 0) {
  //     _propertyType['multifamily'] = this.multifamilyNewArray

  // }
  // if (this.shoppingCenterNewArray.length > 0) {
  //     _propertyType['shopping_Center'] = this.shoppingCenterNewArray
  // }
  // if (this.specialityNewArray.length > 0) {
  //     _propertyType['speciality'] = this.specialityNewArray
  // }
  // if (this.randfNewArray.length > 0) {
  //     _propertyType['speciality'] = this.randfNewArray
  // }
  // end of set _propertyType

  // if (this.automativeMarineArray.length > 0) {
  //     _propertyType['automobile_Marine'] = this.automativeMarineArray
  // }
  // if (this.buildingConstructionArray.length > 0) {
  //     _propertyType['building_Construction'] = this.buildingConstructionArray
  // }
  // if (this.onlineAndTechnology.length > 0) {
  //     _propertyType['online_Technology'] = this.onlineAndTechnology
  // }
  // if (this.serviceIndustryArray.length > 0) {
  //     _propertyType['service_Industry'] = this.serviceIndustryArray
  // }
  // if (this.transportationStorageArray.length > 0) {
  //     _propertyType['transportation_Storage'] = this.transportationStorageArray
  // }
  // if (this.wholesaleAndDistributersArray.length > 0) {
  //     _propertyType['wholeSale_Distributers'] = this.wholesaleAndDistributersArray
  // }
        
        // this.advancedSearchForm.value.officeType.map((item) => {
        //   if (item.selected === true) {
        //     this.officeNewArray.push(item.name)
        //   }
        // }
        // )
        // this.advancedSearchForm.value.land.map((item) => {
        //   if (item.selected === true) {
        //     this.landNewArray.push(item.name)
        //   }
        // }
        // )
        
        // this.advancedSearchForm.value.multifamily.map((item) => {
        //   if (item.selected === true) {
        //     this.multifamilyNewArray.push(item.name)
        //   }
        // }
        // )

        // this.advancedSearchForm.value.Retail.map((item) => {
        //   if (item.selected === true) {
        //     this.retailNewArray.push(item.name)
        //   }
        // }
        // )

        // this.advancedSearchForm.value.Restaurant.map((item) => {
        //   if (item.selected === true) {
        //     this.restaurantNewArray.push(item.name)
        //   }
        // }
        // )
        // this.advancedSearchForm.value.Specialty.map((item) => {
        //   if (item.selected === true) {
        //     this.specialityNewArray.push(item.name)
        //   }
        // }
        // )

        // this.advancedSearchForm.value.Flex.map((item) => {
        //   if (item.selected === true) {
        //     this.flexNewArray.push(item.name)
        //   }
        // }
        // )
        // this.advancedSearchForm.value.shoppingCenter.map((item) => {
        //   if (item.selected === true) {
        //     this.shoppingCenterNewArray.push(item.name)
        //   }
        // }
        // )
        // this.advancedSearchForm.value.Coworking.map((item) => {
        //   if (item.selected === true) {
        //     this.coworkingNewArray.push(item.name)
        //   }
        // }
        // )
        // this.advancedSearchForm.value.Industrial.map((item) => {
        //   if (item.selected === true) {
        //     this.industrialNewArray.push(item.name)
        //   }
        // }
        // )
        // this.advancedSearchForm.value.automotiveAndMarine.map((item) => {
        //   if (item.selected === true) {
        //     this.aandmNewArray.push(item.name)
        //   }
        // }
        // )
        // this.advancedSearchForm.value.serviceIndustry.map((item) => {
        //   if (item.selected === true) {
        //     this.serviceIndustryArray.push(item.name)
        //   }
        // }
        // )
        // this.advancedSearchForm.value.transportationStorage.map((item) => {
        //   if (item.selected === true) {
        //     this.transportationStorageArray.push(item.name)
        //   }
        // }
        // )
        // this.advancedSearchForm.value.Retail.map((item) => {
        //   if (item.selected === true) {
        //     this.wholesaleAndDistributersArray.push(item.name)
        //   }
        // }
        // )
        // this.advancedSearchForm.value.onlineAndTechnology.map((item) => {
        //   if (item.selected === true) {
        //     this.onlineAndTechnologyArray.push(item.name)
        //   }
        // }
        // )

        // this.advancedSearchForm.value.Healthcare.map((item) => {
        //   if (item.selected === true) {
        //     this.healthcareArray.push(item.name)
        //   }
        // }
        // )

        // this.advancedSearchForm.value.Hospitality.map((item) => {
        //   if (item.selected === true) {
        //     this.hospitalityArray.push(item.name)
        //   }
        // }
        // )

        // if(this.officeNewArray.length>0){
        //   _propertyType['office'] = this.officeNewArray
        // }
        // if(this.flexNewArray.length > 0){
        //   _propertyType['flex'] = this.flexNewArray
        // }
        // if(this.coworkingNewArray.length > 0){
        //   _propertyType['coworking'] = this.coworkingNewArray
        // }
        // if(this.industrialNewArray.length>0){
        //   _propertyType['industrial'] = this.industrialNewArray
        // }
        // if(this.retailNewArray.length > 0){
        //   _propertyType['retail'] = this.retailNewArray

        // }
        // if(this.restaurantNewArray.length>0){
        //   _propertyType['restaurant'] = this.restaurantNewArray
        // }
        // if(this.medicalNewArray.length > 0){
        //   _propertyType['medical'] = this.medicalNewArray
        // }

        // if(this.landNewArray.length >0){
        //   _propertyType['land'] = this.landNewArray
        // }
        // if(this.multifamilyNewArray.length > 0){
        //   _propertyType['multifamily'] = this.multifamilyNewArray

        // }
        // if(this.shoppingCenterNewArray.length > 0){
        //   _propertyType['shopping_Center'] = this.shoppingCenterNewArray
        // }
        // if(this.specialityNewArray.length > 0){
        //   _propertyType['speciality'] = this.specialityNewArray
        // }
        // if(this.randfNewArray.length>0){
        //   _propertyType['speciality'] = this.randfNewArray
        // }
        // if(this.onlineAndTechnologyArray.length>0){
        //   _propertyType['online_Technology'] = this.onlineAndTechnology
        // }
        // if(this.serviceIndustryArray.length > 0){
        //   _propertyType['service_Industry'] = this.serviceIndustryArray
        // }
        // if(this.transportationStorageArray.length > 0){
        //   _propertyType['transportation_Storage'] = this.transportationStorageArray
        // }
        // if(this.wholesaleAndDistributersArray.length>0){
        //   _propertyType['wholeSale_Distributers'] = this.wholesaleAndDistributersArray
        // }
        
        if(this.activeTab != ''){
          sendData_['propertyCategory'] = this.activeTab
        }
        let sendData = {
          propertyCategory : this.activeTab,
          propertyStatus: this.advancedSearchForm.value.propertyStatus,
          propertyType : _propertyType,
          maxUnitsRoom: parseInt(this.advancedSearchForm.value.minRooms),
          minUnitsRoom : parseInt(this.advancedSearchForm.value.maxRooms),
          country : this.advancedSearchForm.value.countryName,
          states : this.states,
          metros : this.advancedSearchForm.value.metros,
          professional_Submarket : this.advancedSearchForm.value.professionalSubmarket,
          city : this.city,
          zipCode : parseInt(this.advancedSearchForm.value.zipCode),
          street : this.advancedSearchForm.value.streetAddress,
          maxPrice : parseInt(this.advancedSearchForm.value.maxPrize),
          minPrice : parseInt(this.advancedSearchForm.value.minPrize),
          maxSizeOfBuilding : parseInt(this.advancedSearchForm.value.buildingMaxSize),
          minSizeOfBuilding : parseInt(this.advancedSearchForm.value.buildingMinSize),
          maxYearBuilding : parseInt(this.advancedSearchForm.value.maxYearBuilt),
          minYearBuilding : parseInt(this.advancedSearchForm.value.minYearBuilt),
          resultsPerPage : 12,
          tenancy: this.tenancyNew,
          page : 1
        }
         
        this.pagesServices.userAdvancedSearch(sendData).then((res: any) => {
          if (res.success && res.property.length > 0) {
            this.close(res ,sendData)
          } else {
            this.snackBar.open(res.message, '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
          }
        }) 
  }
  panelClick(o,t)
  {
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      this.states.push(value.trim());
    }

    if (input) {
      input.value = '';
    }
  }

  addCity(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      this.city.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }
  remove(fruit: string): void {
    const index = this.states.indexOf(fruit);

    if (index >= 0) {
      this.states.splice(index, 1);
    }
  }
  removeCity(fruit: string): void {
    const index = this.city.indexOf(fruit);

    if (index >= 0) {
      this.city.splice(index, 1);
    }
  }

  showOptions(event,item){
    if(event.checked == true){
      item.selected = true
      this.tenancyNew.push(item.name)
    } else {
      this.tenancyNew.splice(this.tenancyNew.indexOf("item.name"),1); 
    }
  }

  public buildtenancy() {
    const arr = this.tenancy.map(t => { 
      return this.fb.group({
        name: t.name,
        selected: t.selected
      });
    })   
    return this.fb.array(arr);
  }

  // public buildofficeType() {
  //   let tempArray 
  //   const arr = this.officeType.map(t => { 
  //     return this.fb.group({
  //       name: t.name,
  //       selected: t.selected
  //     });
  //   })   
  //   tempArray = this.fb.array(arr);
  //   return this.fb.array(arr);
  // }

  // public   buildHealthcare() {
  //   const arr = this.Healthcare.map(t => { 
  //     return this.fb.group({
  //       name: t.name,
  //       selected: t.selected
  //     });
  //   })   
  //   return this.fb.array(arr);
  // }

  // public buildlandType() {
  //   const arr = this.land.map(t => { 
  //     return this.fb.group({
  //       name: t.name,
  //       selected: t.selected
  //     });
  //   })   
  //   return this.fb.array(arr);
  // }
  // public buildmultifamilyType() {
  //   const arr = this.multifamily.map(t => { 
  //     return this.fb.group({
  //       name: t.name,
  //       selected: t.selected
  //     });
  //   })   
  //   return this.fb.array(arr);
  // }
  // public buildRetailType() {
  //   const arr = this.Retail.map(t => { 
  //     return this.fb.group({
  //       name: t.name,
  //       selected: t.selected
  //     });
  //   })   
  //   return this.fb.array(arr);
  // }
  // public buildRestaurantType() {
  //   const arr = this.Restaurant.map(t => { 
  //     return this.fb.group({
  //       name: t.name,
  //       selected: t.selected
  //     });
  //   })   
  //   return this.fb.array(arr);
  // }
  // public buildshoppingCenterType() {
  //   const arr = this.shoppingCenter.map(t => { 
  //     return this.fb.group({
  //       name: t.name,
  //       selected: t.selected
  //     });
  //   })   
  //   return this.fb.array(arr);
  // }
  // public buildSpecialtyType() {
  //   const arr = this.Specialty.map(t => { 
  //     return this.fb.group({
  //       name: t.name,
  //       selected: t.selected
  //     });
  //   })   
  //   return this.fb.array(arr);
  // }
  // public buildFlexType() {
  //   const arr = this.Flex.map(t => { 
  //     return this.fb.group({
  //       name: t.name,
  //       selected: t.selected
  //     });
  //   })   
  //   return this.fb.array(arr);
  // }
  // public buildCoworkingType() {
  //   const arr = this.Coworking.map(t => { 
  //     return this.fb.group({
  //       name: t.name,
  //       selected: t.selected
  //     });
  //   })   
  //   return this.fb.array(arr);
  // }
  // public  buildIndustrialType() {
  //   const arr = this.Industrial.map(t => { 
  //     return this.fb.group({
  //       name: t.name,
  //       selected: t.selected
  //     });
  //   })   
  //   return this.fb.array(arr);
  // }
  // public  buildMedicalType() {
  //   const arr = this.Medical.map(t => { 
  //     return this.fb.group({
  //       name: t.name,
  //       selected: t.selected
  //     });
  //   })   
  //   return this.fb.array(arr);
  // }
  // public  buildrestaurantAndFood() {
  //   const arr = this.restaurantAndFood.map(t => { 
  //     return this.fb.group({
  //       name: t.name,
  //       selected: t.selected
  //     });
  //   })   
  //   return this.fb.array(arr);
  // }

  // public   buildautomotiveAndMarine() {
  //   const arr = this.automotiveAndMarine.map(t => { 
  //     return this.fb.group({
  //       name: t.name,
  //       selected: t.selected
  //     });
  //   })   
  //   return this.fb.array(arr);
  // }
  // public   buildserviceIndustry() {
  //   const arr = this.serviceIndustry.map(t => { 
  //     return this.fb.group({
  //       name: t.name,
  //       selected: t.selected
  //     });
  //   })   
  //   return this.fb.array(arr);
  // }

  // public   buildtransportationStorage() {
  //   const arr = this.transportationStorage.map(t => { 
  //     return this.fb.group({
  //       name: t.name,
  //       selected: t.selected
  //     });
  //   })   
  //   return this.fb.array(arr);
  // }
  // public   buildwholesaleAndDistributerse() {
  //   const arr = this.wholesaleAndDistributers.map(t => { 
  //     return this.fb.group({
  //       name: t.name,
  //       selected: t.selected
  //     });
  //   })   
  //   return this.fb.array(arr);
  // }

  // public   buildonlineAndTechnology() {
  //   const arr = this.onlineAndTechnology.map(t => { 
  //     return this.fb.group({
  //       name: t.name,
  //       selected: t.selected
  //     });
  //   })   
  //   return this.fb.array(arr);
  // }

  // public   buildHospitality() {
  //   const arr = this.Hospitality.map(t => { 
  //     return this.fb.group({
  //       name: t.name,
  //       selected: t.selected
  //     });
  //   })   
  //   return this.fb.array(arr);
  // }

  clearAdvanceSearchForm(formdata) {
    this.propertyTypeSale = []
    this.propertyTypeLease = []
    this.city = [];
    this.states = [];
    this.tenancyNew = [];
    this.advancedSearchForm.reset();
  }

  /**
   * this function allow you to enter 
   * only number and price format
   * @param event 
   */
  keyPress(event: any) {
    const pattern = /^[0-9]*\.?\,?[0-9]*$/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != BACKSPACE && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
}
