// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  backendUrl : 'https://finddirt.com:3000/api',
  // backendUrl : 'http://localhost:3000/api',
  headUrl: "learngis2.maps.arcgis.com/apps/Embed/index.html?webmap=74128c1b0a0b474898ccab082fa0b340&home=true&zoom=true&scale=true&search=true&searchextent=true&disable_scroll=true&theme=light&marker=",
  contentUrl: "//learngis2.maps.arcgis.com/apps/Embed/index.html?webmap=74128c1b0a0b474898ccab082fa0b340&home=true&zoom=true&previewImage=true&scale=true&search=true&searchextent=true&disable_scroll=true&theme=light&marker="
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
