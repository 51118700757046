import { AfterViewInit, Component, OnInit } from '@angular/core';
import { PagesService } from '../../../pages/pages.service';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent implements OnInit , AfterViewInit{
  userName = '';
  id = 5;
  unsubscribed = {
    email: ''
  };
  userType;
  userData;
  userImage
  affiliation: any

  constructor(
    public pagesService: PagesService
    ) {
      this.pagesService.profileUpdated.subscribe(item=>{
        this.userImage = item
      })
    }



  ngOnInit() {
    this.userName = sessionStorage.getItem('userName') || localStorage.getItem('userName');
    this.pagesService.currentUser.subscribe(item => {
      this.userType = item;
    });
    if (this.userType === ' ') {
      this.userType = sessionStorage.getItem('userType');
    }
    this.getUserData()
  
  }

ngAfterViewInit(){
  this.pagesService.profileUpdated.subscribe(item=>{
    this.userImage = item
  })
}

  public signOut() {
    this.pagesService.changeMessage(false);
    this.pagesService.setUserType(this.unsubscribed);
    localStorage.clear();
    sessionStorage.clear();
  }
  getUserData() {
    this.pagesService.getUserProfileData().then((res: any) => {
      this.userData = res.user
      this.userImage = res.user.image_Url
      if (res && res.user && res.user.affiliation) {
        this.affiliation = JSON.parse(res.user.affiliation)
      }
    })
  }

}
