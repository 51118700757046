import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
    selector: 'app-mission',
    templateUrl: './mission.component.html',
    styleUrls: ['./mission.component.scss']
})
export class MissionComponent implements OnInit {

    panelOpenState = false;
    safeSrc: SafeResourceUrl;
    constructor(private sanitizer: DomSanitizer) {
        this.safeSrc = this.sanitizer.bypassSecurityTrustResourceUrl("https://www.youtube.com/embed/v7wCoa3EOvU");
    }

    ngOnInit() {
    }


    // Read More Function to toggle text //
    // Strart//
    readMore(ele, moreEle, btnEle) {
        let dots = document.getElementById(ele);
        let moreText = document.getElementById(moreEle);
        let btnText = document.getElementById(btnEle);

        if (dots.style.display === "none") {
            dots.style.display = "inline";
            btnText.innerHTML = "Read more";
            moreText.style.display = "none";
        } else {
            dots.style.display = "none";
            btnText.innerHTML = "Read less";
            moreText.style.display = "inline";
        }
    }
    // End Code //
}