import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-think-page',
  templateUrl: './think-page.component.html',
  styleUrls: ['./think-page.component.scss']
})
export class ThinkPageComponent implements OnInit {

@ViewChild('openPreview', { static: true }) openPreview: TemplateRef<any>;
dialogRefs = null;
showDesktop =false ;
showLaptop=false;
showMobile=false;

  constructor(private dialog: MatDialog,private router: Router) { }

  ngOnInit(): void {
  }
openImageDialog(type){
  if(type=='desktop'){
    this.showDesktop=true
    this.showLaptop=false
    this.showMobile=false
  }
  if(type=='laptop'){
    this.showLaptop=true
    this.showDesktop=false
    this.showMobile=false
  }
  if(type=='mobile'){
    this.showMobile=true
    this.showDesktop=false
    this.showLaptop=false
  }
  this.dialogRefs = this.dialog.open(this.openPreview, {
    width: '600px',
    // disableClose: true
    //panelClass:'contactpopup',
  });
}

gotoOurTool(){
  this.router.navigate(['/tool-details'])
}

}
