import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { data } from 'jquery';
@Injectable({
    providedIn: 'root'
})
export class PagesService {
    public _propertySearchData = new BehaviorSubject<any>([]);
    public _propertySendData = new BehaviorSubject<any>([]);
    private messageSource = new BehaviorSubject(false);
    private userType = new BehaviorSubject('');
    private paymentId = new BehaviorSubject('')
    private dataStore: { todos: any } = { todos: [] };
    private dataStorSearchData: { SearchData: any } = { SearchData: [] };
    readonly todos = this._propertySearchData.asObservable();
    readonly propertySearchData = this._propertySearchData.asObservable();
    readonly propertySendData = this._propertySendData.asObservable();
    propertyData = this._propertySearchData.asObservable();
    currentMessage = this.messageSource.asObservable();
    currentUser = this.userType.asObservable();
    currentPaymentId = this.paymentId.asObservable()
    backendUrl = environment.backendUrl;
    loggedIn = false;
    address = '';
    loggedinProperties = false;
    profileUpdated: EventEmitter<any> = new EventEmitter<any>()

    constructor(public router: Router,
        private http: HttpClient) { }

    StorePropertyData(data, sendData) {
        this.dataStore.todos = data;
        this.dataStorSearchData.SearchData = sendData;

        this._propertySearchData.next(Object.assign({}, this.dataStore).todos);
        this._propertySendData.next(Object.assign({}, this.dataStorSearchData).SearchData);
    }

    changeMessage(data) {
        this.loggedIn = data;
        this.messageSource.next(data);
    }

    setPaymentId(id) {
        this.paymentId.next(id)
    }
    setUserType(value) {
        if (value === 'subscriber') {
            this.userType.next('subscriber');
            localStorage.setItem('userType', 'subscriber');
        } else if (value === 'subscriber2') {
            this.userType.next('subscriber2');
            localStorage.setItem('userType', 'subscriber2');
        }
        else if (value === 'subscriber1') {
            this.userType.next('subscriber1');
            localStorage.setItem('userType', 'subscriber1');
        }
        else if (value === 'admin') {
            this.userType.next('admin');
            localStorage.setItem('userType', 'admin');
        }
        if (value.email === '') {
            this.userType.next('');
        }
    }

    getSubscriberPlan() {
        return this.http.get(this.backendUrl + '/subscriber/get-plans')        
        .toPromise()
        .then(res => res)
        .catch(err => this.handleError(err));
    }

    getAllUser(page, pageSize) {
        return this.http.get(this.backendUrl + '/admin/all-user/' + page + '/' + pageSize)
        .toPromise()
        .then(res => res)
        .catch(err => this.handleError(err));
    }

    getAllSubscribers(page, pageSize) {
        return this.http.get(this.backendUrl + '/admin/all-subscriber/' + page + '/' + pageSize)
        .toPromise()
        .then(res => res)
        .catch(err => this.handleError(err));
    }

    getUserProfileData() {
        return this.http.get(this.backendUrl + '/user')
        .toPromise()
        .then(res => res)
        .catch(err => this.handleError(err));
    }

    updateUserProfile(data) {
        return this.http.post(this.backendUrl + '/user/update-profile', data)
        .toPromise()
        .then(res => res)
        .catch(err => this.handleError(err));
    }

    getPropertyById(id) {
        // return this.http.get(this.backendUrl + '/subscriber/property/' + id);
        return this.http.get(this.backendUrl + '/user/property/' + id)
        .toPromise()
        .then(res => res)
        .catch(err => this.handleError(err));
    }

    deleteProprty(id) {
        return this.http.post(this.backendUrl + '/subscriber/delete-property/' + id, '')
        .toPromise()
        .then(res => res)
        .catch(err => this.handleError(err));
    }

    getAllProperties(page, pageSize) {
        return this.http.get(this.backendUrl + '/subscriber/get-subscriber-property/' + page + '/' + pageSize)
        .toPromise()
        .then(res => res)
        .catch(err => this.handleError(err));
    }

    deleteAdminCompData(data){
        return this.http.post(this.backendUrl + '/comp/remove',data)
        .toPromise()
        .then(res=>res)
        .catch(err=>this.handleError(err))
    }

    getAdminCompData(page, pageSize) {
        return this.http.get(this.backendUrl + '/comp/' + page + '/' + pageSize)
        .toPromise()
        .then(res => res)
        .catch(err => this.handleError(err))
    }

    deleteAllAdminCompData(data){
        return this.http.post(this.backendUrl + '/comp/remove', data)
        .toPromise()
        .then(res=>res)
        .catch(err=>this.handleError(err))
    }

    addSubscriber(data) {
        return this.http.post(this.backendUrl + '/subscriber/subscription', data)
        .toPromise()
        .then(res => res)
        .catch(err => this.handleError(err));
    }

    cancelSubscription() {
        return this.http.get(this.backendUrl + '/subscriber/cancel-subscription')
        .toPromise()
        .then(res => res)
        .catch(err => this.handleError(err));
    }

    getCurrentActivePlan() {
        return this.http.get(this.backendUrl + '/subscriber/get-subscription')
        .toPromise()
        .then(res => res)
        .catch(err => this.handleError(err));
    }

    getAllCards() {
        return this.http.get(this.backendUrl + '/subscriber/get-all-cards')
        .toPromise()
        .then(res => res)
        .catch(err => this.handleError(err));
    }

    getBillingHistory() {
        return this.http.get(this.backendUrl + '/subscriber/get-billing-history')
        .toPromise()
        .then(res => res)
        .catch(err => this.handleError(err));
    }

    getPdfData(data) {
        return this.http.post(this.backendUrl + '/subscriber/getPdf', data)
        .toPromise()
        .then(res => res)
        .catch(err => this.handleError(err));
    }

    checkoutPayment(data) {
        return this.http.post(this.backendUrl + '/subscriber/payment', data)
        .toPromise()
        .then(res => res)
        .catch(err => this.handleError(err));
    }

    makeCardDefalut(data) {
        return this.http.post(this.backendUrl + '/subscriber/make-card-default', data)
        .toPromise()
        .then(res => res)
        .catch(err => this.handleError(err));
    }

    deleteCard(data) {
        return this.http.post(this.backendUrl + '/subscriber/delete-cards', data)
        .toPromise()
        .then(res => res)
        .catch(err => this.handleError(err));
    }

    editPlans(data) {
        return this.http.post(this.backendUrl + '/admin/update-plane', data)
        .toPromise()
        .then(res => res)
        .catch(err => this.handleError(err));
    }

    editsubscribersDetails(data) {
        return this.http.post(this.backendUrl + '/admin/edite-rates', data)
        .toPromise()
        .then(res => res)
        .catch(err => this.handleError(err));
    }

    activeSuspendsubscriber(data) {
        return this.http.post(this.backendUrl + '/admin/activate-suspend', data)
        .toPromise()
        .then(res => res)
        .catch(err => this.handleError(err));
    }

    activeSuspendUseer(data) {
        return this.http.put(this.backendUrl + '/admin/suspend_user_v2', data)
        .toPromise()
        .then(res => res)
        .catch(err => this.handleError(err));
    }

    search(data) {
        return this.http.post(this.backendUrl + '/admin/search', data)
        .toPromise()
        .then(res => res)
        .catch(err => this.handleError(err));
    }

    loginSubscriberAccount(data) {
        return this.http.post(this.backendUrl + '/admin/login-subscriber-account', data)
        .toPromise()
        .then(res => res)
        .catch(err => this.handleError(err));
    }

    signup(data) {
        return this.http.post(this.backendUrl + '/user/signup', data)
        .toPromise()
        .then(res => res)
        .catch(err => this.handleError(err));
    }
    login(data) {
        return this.http.post(this.backendUrl + '/user/login', data)
        .toPromise()
        .then(res => res)
        .catch(err => this.handleError(err));
    }
    ForgotPassword(data) {
        return this.http.post(this.backendUrl + '/user/forgot-password', data)
        .toPromise()
        .then(res => res)
        .catch(err => this.handleError(err));
    }
    resetPassword(data) {
        return this.http.post(this.backendUrl + '/user/reset-password', data)
        .toPromise()
        .then(res => res)
        .catch(err => this.handleError(err));
    }
    submitProperty(data) {
        console.log(data);
        return this.http.post(this.backendUrl + '/subscriber/create-property', data)
        .toPromise()
        .then(res => res)
        .catch(err => this.handleError(err));
    }
    uploadTrafficData(data) {
        return this.http.post(this.backendUrl + '/comp/upload', data)
        .toPromise()
        .then(res => res)
        .catch(err => this.handleError(err));
    }
    getTrafficData(data) {
        return this.http.get(this.backendUrl + '/traffic/'+ data.lat + '/' + data.lng)
        .toPromise()
        .then(res => res)
        .catch(err => this.handleError(err));
    }
    getCompData(data) {
        return this.http.post(this.backendUrl + '/comp/', data)
        .toPromise()
        .then(res => res)
        .catch(err => this.handleError(err));
    }
    getCompPropertyDetails(id) {
        return this.http.get(this.backendUrl + '/comp/' + id )
        .toPromise()
        .then(res => res)
        .catch(err => this.handleError(err));
    }
    userAdvancedSearch(data) {
        return this.http.post(this.backendUrl + '/user/advanced-search', data)
        .toPromise()
        .then(res => res)
        .catch(err => this.handleError(err));
    }

    getMappingData(data) {
        return this.http.post(this.backendUrl + '/map',data)
        .toPromise()
        .then(res=>res)
        .catch(err=>this.handleError(err))
    }

    getSectorsAndBrand(){
        return this.http.get(this.backendUrl +'/map')
        .toPromise()
        .then(res=>res)
        .catch(err=>this.handleError(err))
    }
    getAllUserProperties(pageSize, page) {
        return this.http.get(this.backendUrl + '/user/all-property/' + pageSize + '/' + page)
        .toPromise()
        .then(res => res)
        .catch(err => this.handleError(err));
    }

    exportData(type) {
        return this.http.get(this.backendUrl + '/admin/export-user/' + type)
        .toPromise()
        .then(res => res)
        .catch(err => this.handleError(err));
    }

    editProperty(data, id) {
        return this.http.post(this.backendUrl + '/subscriber/update-property/' + id, data)
        .toPromise()
        .then(res => res)
        .catch(err => this.handleError(err));
    }
    changePassword(data) {
        return this.http.post(this.backendUrl + '/user/change-password', data)
        .toPromise()
        .then(res => res)
        .catch(err => this.handleError(err));
    }
    userDemographics(data) {
        return this.http.post(this.backendUrl + '/user/demographic', data)
        .toPromise()
        .then(res => res)
        .catch(err => this.handleError(err));
    }
    userPropertyRecords(data) {
        return this.http.post(this.backendUrl + '/subscriber/property-records', data)
        .toPromise()
        .then(res => res)
        .catch(err => this.handleError(err));
    }

    sendUserEmail(data){
        return this.http.post(this.backendUrl + '/user/send_email' , data )
        .toPromise()
        .then(res=>res)
        .catch(err=>this.handleError(err))
    }

    // mapPropertyRecord(data){
    //     return this.http.post(this.backendUrl +'/subscriber/sale-property-records',data )
    //     .toPromise()
    //     .then(res=>res)
    //     .catch(err=>this.handleError(err))
    // }

    handleError(error: any) {
        console.error('An error occurred', error);
        if(error.status == 401){
            this.router.navigate(['login']);
        }else{
            this.router.navigate(['notFound']);
        }
        return error.message || error;
      }

      deleteUserAccount(id) {
        return this.http.delete(this.backendUrl + '/user/delete/' + id)
        .toPromise()
        .then(res => res)
        .catch(err => this.handleError(err));
    }

    addBlog(data) {
        return this.http.post(this.backendUrl + '/admin/blog/create-blog', data)
        .toPromise()
        .then(res => res)
        .catch(err => this.handleError(err));
    }

    uploadBlog(data) {
        return this.http.post(this.backendUrl + '/admin/blog/update-blog', data)
        .toPromise()
        .then(res => res)
        .catch(err => this.handleError(err));
    }

    deleteBlog(blogId) {
        return this.http.delete(this.backendUrl + `/admin/blog/delete-blog/${blogId}`)
        .toPromise()
        .then(res => res)
        .catch(err => this.handleError(err));
    }

    getAllBlogs(category = null) {
        return this.http.get(this.backendUrl + `/admin/blog/get-blogs?category=${category}`)
        .toPromise()
        .then(res => res)
        .catch(err => this.handleError(err));
    }
    
    getBlogDetails(blogId) {
        return this.http.get(this.backendUrl + `/admin/blog/get-blog/${blogId}`)
        .toPromise()
        .then(res => res)
        .catch(err => this.handleError(err));
    }

}
