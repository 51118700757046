import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-card-payment-confirmation-modal',
  templateUrl: './card-payment-confirmation-modal.component.html',
  styleUrls: ['./card-payment-confirmation-modal.component.scss']
})
export class CardPaymentConfirmationModalComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<CardPaymentConfirmationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog
    ) { }

  ngOnInit(): void {
  }
  
  confirm(){
    this.dialogRef.close(this.data);
  }

  close(d){
    this.dialogRef.close();
  }

}
