import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { LoginService } from '../login/login.service';
import { Router } from '@angular/router';
import { PagesService } from '../pages.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.scss']
})
export class ResetpasswordComponent implements OnInit {

  public resetPasswordForm: FormGroup;
  public loginservices: LoginService;
  public hide = true;

  constructor(public fb: FormBuilder, public router: Router,
    public pagesServices: PagesService,
    public snackBar: MatSnackBar) { }


  ngOnInit(): void {
    this.resetPasswordForm = this.fb.group({
      email: [null, Validators.compose([Validators.required, Validators.email])],
    });
  }
  onResetPassword(value) {
    if (this.resetPasswordForm.invalid) {
      return false;
    }
    const sendData = {
      email : value.email,
      resetPasswordUrl :'http://finddirt.com/'
    }
    this.pagesServices.ForgotPassword(sendData).then((res: any) => {
      if (res.success) {
        this.snackBar.open(res.message, '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
      } else {
        this.snackBar.open(res.message, '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
      }
    })
  }
}
