import { Component, OnInit, Input, ViewChild, SimpleChange } from '@angular/core';
import { SwiperDirective, SwiperConfigInterface, SwiperPaginationInterface } from 'ngx-swiper-wrapper';
import { Property } from '../../app.models';
import { Settings, AppSettings } from '../../app.settings';

import { AppService } from '../../app.service';
import { CompareOverviewComponent } from '../compare-overview/compare-overview.component';
import { DecimalPipe } from '@angular/common';

@Component({
    selector: 'app-property-item',
    templateUrl: './property-item.component.html',
    styleUrls: ['./property-item.component.scss']
})
export class PropertyItemComponent implements OnInit {
    @Input() property;
    @Input() viewType: string = "grid";
    @Input() viewColChanged: boolean = false;
    @Input() fullWidthPage: boolean = true;
    public column: number = 4;
    // public address:string; 
    @ViewChild(SwiperDirective) directiveRef: SwiperDirective;
    public config: SwiperConfigInterface = {};
    private pagination: SwiperPaginationInterface = {
        el: '.swiper-pagination',
        clickable: true
    };
    public settings: Settings;
    constructor(public appSettings: AppSettings,
        public appService: AppService,
        private decimalPipe: DecimalPipe) {
        this.settings = this.appSettings.settings;
    }

    ngOnInit() { 
        if(typeof(this.property.property_Address) === 'string'){
            this.property.property_Address = JSON.parse(this.property.property_Address)
            // this.property.property_Address = JSON.parse(res.property.property_Address);
        }
        if(typeof(this.property.property_Address) === 'object'){
            this.property.property_Address = this.property.property_Address;
        }
        if(typeof(this.property.property_Type) === 'string'){
            this.property.property_Type = JSON.parse(this.property.property_Type)
            // this.property.property_Address = JSON.parse(res.property.property_Address);
        }
        if(typeof(this.property.property_Type) === 'object'){
            this.property.property_Type = this.property.property_Type;
        }
        // console.log('property from property-item-component===>>', this.property)
    }

    ngAfterViewInit() {
        this.initCarousel();
    }

    details() {
    }

    ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
        if (changes.viewColChanged) {
            this.getColumnCount(changes.viewColChanged.currentValue);
            if (!changes.viewColChanged.isFirstChange()) {
                if (this.property && this.property.gallery && this.property.gallery.length > 1) {
                    this.directiveRef.update();
                }
            }
        }

        for (let propName in changes) {
            // let changedProp = changes[propName];
            // if (!changedProp.isFirstChange()) {
            //   if(this.property.gallery.length > 1){
            //     this.initCarousel();
            //     this.config.autoHeight = true;       
            //     this.directiveRef.update();  
            //   }       
            // }      
        }
    }

    public getColumnCount(value) {
        if (value == 25) {
            this.column = 4;
        }
        else if (value == 33.3) {
            this.column = 3;
        }
        else if (value == 50) {
            this.column = 2
        }
        else {
            this.column = 1;
        }
    }

    public getStatusBgColor(status) {
        switch (status) {
            case 'For Sale':
                return '#558B2F';
            case 'For Rent':
                return '#1E88E5';
            case 'Open House':
                return '#009688';
            case 'No Fees':
                return '#FFA000';
            case 'Hot Offer':
                return '#F44336';
            case 'Sold':
                return '#000';
            default:
                return '#01579B';
        }
    }


    public initCarousel() {
        this.config = {
            slidesPerView: 1,
            spaceBetween: 0,
            keyboard: false,
            navigation: true,
            pagination: this.pagination,
            grabCursor: true,
            loop: true,
            preloadImages: false,
            lazy: true,
            nested: true,
            // autoplay: {
            //   delay: 5000,
            //   disableOnInteraction: false
            // },
            speed: 500,
            effect: "slide"
        }
    }


    public addToCompare() {
        this.appService.addToCompare(this.property, CompareOverviewComponent, (this.settings.rtl) ? 'rtl' : 'ltr');
    }

    public onCompare() {
        return this.appService.Data.compareList.filter(item => item.id == this.property.id)[0];
    }

    public addToFavorites() {
        this.appService.addToFavorites(this.property, (this.settings.rtl) ? 'rtl' : 'ltr');
    }

    public onFavorites() {
        return this.appService.Data.favorites.filter(item => item.id == this.property.id)[0];
    }

    public getFormattedValue(num) {
        if (num) {
            const value = Number(num);
            return this.decimalPipe.transform(value);
        } else {
            return '--';
        }
    }
}
