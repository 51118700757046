import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { matchingPasswords } from 'src/app/theme/utils/app-validators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PagesService } from '../../pages.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  resetToken = ''
  public changePasswordForm: FormGroup;
  constructor(public fb: FormBuilder, private route: ActivatedRoute, private router: Router,
    public pagesServices: PagesService,
    public snackBar: MatSnackBar
  ) {
    this.route.params.subscribe(params => {
      this.resetToken = params.token;
    });
  }

  ngOnInit() {
    this.changePasswordForm = this.fb.group({
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required],
    }, { validator: matchingPasswords('password', 'confirmPassword') });
  }

  changePasswordFormSubmit(formValue) {
    if (this.changePasswordForm.invalid) {
      return false;
    }
    const sendData = {
      validationToken: this.resetToken,
      password: formValue.password
    }
    this.pagesServices.resetPassword(sendData).then((res: any) => {
      if (res.success) {
        this.snackBar.open(res.message, '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
        setTimeout(() => {
          this.router.navigate(['/login'])
        }, 3000);
      } else {
        this.snackBar.open(res.message, '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
      }
    })
  }

}
