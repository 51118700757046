import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PagesService } from 'src/app/pages/pages.service';

@Injectable()
export class AppInterceptor implements HttpInterceptor {
    unsubscribed = {
      email: ''
    };
    constructor(
      public router: Router,
      public snackBar: MatSnackBar,
      public pagesService: PagesService
      ) {}
  
    intercept (req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
     let token = localStorage.getItem('token')
        if(sessionStorage.getItem('subscriberToken') && sessionStorage.getItem('subscriberToken') != undefined){
          req = req.clone({
            
            setHeaders: {
              'x-access-token': sessionStorage.getItem('subscriberToken')
            }
          });
        }
        else {
          if(req.url.includes('https://maps.googleapis.com/maps/api/geocode/json?latlng=')){
              req = req.clone({
              });
          } else {
            if(token) {
              req = req.clone({
              
                setHeaders: {
                  'x-access-token': token
                }
              });
            }
          }

        }
        return next.handle(req).pipe(map((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
            }
            return event;
          }),
          catchError((error: HttpErrorResponse) => {
            if(error.error['message']=== "Token expired !"){
              this.snackBar.open(error.error['message'], '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
              setTimeout(() => {
                  this.router.navigate(['login'])
                  this.pagesService.changeMessage(false);
                  this.pagesService.setUserType(this.unsubscribed);
                  localStorage.clear();
                  sessionStorage.clear();
              }, 3000);
            }
            const started = Date.now();            
            const elapsed = Date.now() - started;
            return throwError(error);
          })
        );

    }  
}