import { Component, OnInit, NgZone, ElementRef, ViewChild } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { MapsAPILoader } from '@agm/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PagesService } from '../../pages.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { ChartType, ChartOptions } from 'chart.js';
import { SingleDataSet, Label } from 'ng2-charts';

@Component({
    selector: 'app-demographics',
    templateUrl: './demographics.component.html',
    styleUrls: ['./demographics.component.scss']
})

export class DemographicsComponent implements OnInit {

    @ViewChild('addressAutocomplete') addressAutocomplete: ElementRef;
    startAutocomplete;
    newAddress: any;
    location: Array<any>;
    addressComplete = false;
    public form: FormGroup;
    public completeAddress = '';
    dialogRef: any;
    lat: number;
    long: number;
    distanceArr1: any[] = [];
    distanceArr2: any[] = [];
    distanceArr3: any[] = [];
    constructor(public appService: AppService, private mapsAPILoader: MapsAPILoader,
        private ngZone: NgZone, public fb: FormBuilder, public dialog: MatDialog,
        public router: Router, public pagesService: PagesService, public snackBar: MatSnackBar) {
    }

    // Pie Chart
    public pieChartOptions: ChartOptions = {
        responsive: true,
        title: {
            text: '',
            display: true
        },
        scales: {
            yAxes: [
             {
                 display: true,
                 ticks: {
                   fontSize: 18,
                 },
                 gridLines: {
                  }
             }
           ],
           xAxes: [
            {
                display: true,
                ticks: {
                  fontSize: 18,
                },
                gridLines: {
                  }
            }
          ]
         },
        legend: {
            position: 'top',
            labels: {
              fontSize: 22,
            }
        }
    };
    public pieChartLabels: Label[] = [];
    public pieChartData: SingleDataSet = [];
    public dataSet = [];
    public pieChartType: ChartType = 'horizontalBar';
    public pieChartPlugins = [];
    public pieChartLegend = [];
    public pieChartColors: any[] = [
        {
            borderColor: 'black',
            backgroundColor: '#FF8300',
        },
    ];

    ngOnInit() {
        // Address 
        this.form = this.fb.group({
            completeAddress: '',
            lat: '',
            long: '',
            address: this.fb.group({
                completeAddress: ['', Validators.required],
                location: '',
            }),
        });
    }
    ngAfterViewInit() {
        this.placesAutocomplete();
    }
    private placesAutocomplete() {
        this.mapsAPILoader.load().then(() => {
            let autocomplete = new google.maps.places.Autocomplete(this.addressAutocomplete.nativeElement, {
                types: ["address"]
            });
            autocomplete.addListener("place_changed", () => {
                this.ngZone.run(() => {
                    let place: google.maps.places.PlaceResult = autocomplete.getPlace();
                    this.completeAddress = place.formatted_address
                    this.form.controls.address.get('completeAddress').setValue(place.formatted_address)
                    this.form.controls.address.get('location').setValue(this.location)
                    if (place.geometry === undefined || place.geometry === null) {
                        return;
                    };
                    this.lat = place.geometry.location.lat();
                    this.long = place.geometry.location.lng();
                  // this.getAddress();
                });
            });
        });
    }
    // public getAddress() {
    //     this.appService.getAddress(this.lat, this.long).subscribe(response => {
    //         let address = response['results'][0].formatted_address;
    //         this.completeAddress = address
    //         this.form.controls.address.get('completeAddress').setValue(address);
    //     })
    // }

    submitSearchForm() {
        if (this.form.invalid) {
            this.snackBar.open("Please enter Location", '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
            return false
        }
        let sendData = {
            lat: this.lat,
            long: this.long
        }
        this.pagesService.userDemographics(sendData).then((res: any) => {
            if (res.success) {
                this.pieChartLabels = [];
                this.pieChartData = [];
                let arr: any[] = [];
                let keys = Object.keys(res.arrayOfData)
                keys.splice(3, 3)
                keys.splice(4, 3)
                keys.filter(item => {
                    let newArr = [];
                    this.pieChartLabels.push(Object.keys(res.arrayOfData[item]));
                    const value = Object.values(res.arrayOfData[item]);
                    newArr.push({ data: value, label: item.toUpperCase() })
                    arr.push(Object.values(res.arrayOfData[item]));
                    this.dataSet.push(newArr);
                })
                this.pieChartData = arr;
                let i, temp = []
                for (i = 0; i < this.pieChartLabels.length; i++) {
                    temp.push({ labels: this.pieChartLabels[i], value: this.pieChartData[i] })
                }

                this.distanceArr1 = [];
                for (let [key, value] of Object.entries(res.arrayOfData[0])) {
                    if (key.indexOf('20') === 0) {
                        this.distanceArr1.push({key, value})
                        console.log(this.distanceArr1)
                    }
                }
                this.distanceArr2 = [];

                for (let [key, value] of Object.entries(res.arrayOfData[1])) {
                    if (key.indexOf('20') === 0) {
                        this.distanceArr2.push({key, value})
                    }
                }
                this.distanceArr3 = [];
                for (let [key, value] of Object.entries(res.arrayOfData[2])) {
                    if (key.indexOf('20') === 0) {
                        this.distanceArr3.push({key, value})
                    }
                }
                this.snackBar.open(res.message, '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
            } else {
                this.snackBar.open(res.message, '×', { panelClass: 'failed', verticalPosition: 'top', duration: 3000 });
            }

        })
    }
    close(responseData, sendDataNew) {
        this.dialogRef.close({ data: responseData, searchSendData: sendDataNew });
    }
}


