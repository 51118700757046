import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PagesService } from 'src/app/pages/pages.service';

@Component({
  selector: 'app-delete-alert',
  templateUrl: './delete-alert.component.html',
  styleUrls: ['./delete-alert.component.scss']
})
export class DeleteAlertComponent implements OnInit {
dataLength;

  constructor(
  @Inject(MAT_DIALOG_DATA) public data: any,
  public _dialogRef: MatDialogRef<DeleteAlertComponent>,
  public pagesServices: PagesService,
  ) { }

  ngOnInit(): void {
    this.dataLength = this.data.ids.length
  }

  onNoClick(): void {
    this._dialogRef.close((['result']['status'] = false));
  }
async deleteData(){
  console.log(this.data)
  this._dialogRef.close((['result']['status'] = true));
}

}
