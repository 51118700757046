import { Component, OnInit, Input } from '@angular/core';
import { MenuService } from '../menu.service';
import { Menu } from '../menu.model';
import { PagesService } from '../../../../pages/pages.service';
import { L } from '@angular/cdk/keycodes';

@Component({
  selector: 'app-vertical-menu',
  templateUrl: './vertical-menu.component.html',
  styleUrls: ['./vertical-menu.component.scss'],
  providers: [ MenuService ]
})
export class VerticalMenuComponent implements OnInit {
  loggedIn = false;
  userType = '';
  @Input('menuParentId') menuParentId;
  public menuItems: Array<Menu>;
  public userItem: Array<Menu>;
  public subscriberItem: Array<Menu>;
  public adminItem: Array<Menu>
  constructor(public menuService:MenuService , public pagesServices:PagesService) { }

  ngOnInit() {
    this.menuItems = this.menuService.getVerticalMenuItems();
    this.userItem = this.menuService.getuserMenu();
    this.subscriberItem = this.menuService.getsubscriberMenu();
    this.adminItem = this.menuService.getAdminMenu();
    this.menuItems = this.menuItems.filter(item => item.parentId == this.menuParentId);
    this.pagesServices.currentMessage.subscribe(item => {
      setTimeout(() => {
        if(!localStorage.getItem('loggedIn')) {
          this.loggedIn = false;
        } else {
          this.loggedIn = true;
        }
      }, 200);
    });
    this.pagesServices.currentUser.subscribe(item => {
      this.userType = item;
    });
    this.userType = localStorage.getItem('subscriberdetail');
    this.userType = sessionStorage.getItem('userType') || localStorage.getItem('userType');
  }

  onClick(menuId){
    this.menuService.toggleMenuItem(menuId);
    this.menuService.closeOtherSubMenus(this.menuService.getVerticalMenuItems(), menuId);

  }

}
