import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { Router, NavigationEnd } from '@angular/router';
import { PagesService } from 'src/app/pages/pages.service';

@Component({
  selector: 'app-toolbar1',
  templateUrl: './toolbar1.component.html'
})
export class Toolbar1Component implements OnInit {
  loggedIn ;
  @Output() onMenuIconClick: EventEmitter<any> = new EventEmitter<any>();
  constructor(public appService:AppService , public router:Router , public pagesServices:PagesService) { }

  ngOnInit() {  this.pagesServices.currentMessage.subscribe(item => {
    this.loggedIn = item;
  });
  let logIn = localStorage.getItem('loggedIn')
  if (logIn && logIn == "true") {
    this.loggedIn = true
  }
}
  public home() {
    this.router.navigate(['/']);


  }

  public sidenavToggle(){
    this.onMenuIconClick.emit();
  }
}