import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'MM-DD-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};
@Component({
  selector: 'app-date-wheel',
  templateUrl: './date-wheel.component.html',
  styleUrls: ['./date-wheel.component.scss'],
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class DateWheelComponent implements OnInit {
  dateWheelForm: FormGroup;
  dayTypes: string = 'day'

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    moment();
    this.dateWheelForm = this.formBuilder.group({
      start_date: [moment().format(), Validators.required],
      days: ['', Validators.required],
      end_date: [moment().add(7, 'days').format(), Validators.required]
    });
  }

  toggleButtonChange(event) {
    if(event.value === 'day'){
      this.dayTypes = 'day'
    }
    else if(event.value === 'business'){
      this.dayTypes = 'business'
    }
    this.calculateBusinessDays(event)
  }

  dayChange(event) {
    if(this.dayTypes === 'day'){
      const value = event.target.value
      this.dateWheelForm.controls.end_date.setValue(moment().add(value, 'days').format())
    }
    else if(this.dayTypes === 'business'){
      const value = event.target.value
      const offdays = (value / 7) * 2
      const totalDays = parseInt(value) + offdays
      const firstDate = this.dateWheelForm.value.start_date
      var startDateMoment = moment(firstDate);
      var days = totalDays;
      if (startDateMoment.day() === 6) {
        days++;
      }
      if(startDateMoment.day() === 7){
        days++;
      }
      this.dateWheelForm.controls.end_date.setValue(moment(firstDate).add(days, 'days').format())
    }
  }

  endDateChange(event) {
    if(event.value){
      const date1: any = new Date(this.dateWheelForm.value.start_date);
      const date2: any = new Date(this.dateWheelForm.value.end_date);
      const diffDays: any = Math.floor((date2 - date1) / (1000 * 60 * 60 * 24));
      this.dateWheelForm.controls.days.setValue(diffDays)
    }else{
      this.dateWheelForm.controls.days.setValue(0)
    }
  }

  startDateChange(){

  }

  calculateBusinessDays(event) {

    if (this.dayTypes === 'day') {
      this.endDateChange(event)
    }
    if (this.dayTypes === 'business'){
      const secondDate = this.dateWheelForm.value.start_date;
      const firstDate = this.dateWheelForm.value.end_date;
      var startDateMoment = moment(firstDate);
      var endDateMoment = moment(secondDate)
      var days = Math.round(startDateMoment.diff(endDateMoment, 'days') - startDateMoment .diff(endDateMoment, 'days') / 7 * 2);
      if (endDateMoment.day() === 6) {
        days--;
      }
      if (startDateMoment.day() === 7) {
        days--;
      }
      this.dateWheelForm.controls.days.setValue(days)
    }
  }
  allowDateKeys({ keyCode }) {
    if (
      (keyCode >= 47 && keyCode <= 57 && keyCode !== 101 && keyCode !== 69) ||
      keyCode === 13 ||
      keyCode === 111
    ) {
      return true;
    } else {
      return false;
    }
  }
}
