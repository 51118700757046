import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { NgProgressModule } from 'ngx-progressbar';
import { NgProgressHttpModule } from 'ngx-progressbar/http';
import { AgmCoreModule } from '@agm/core';
import { EmbedVideo } from 'ngx-embed-video';
import { InputFileConfig, InputFileModule } from 'ngx-input-file';
const config: InputFileConfig = {
    fileAccept: '*'
};

import { OverlayContainer } from '@angular/cdk/overlay';
import { CustomOverlayContainer } from './theme/utils/custom-overlay-container';

import { AppRouting } from './app.routing';
import { SharedModule } from './shared/shared.module';
import { AppComponent } from './app.component';
import { AppSettings } from './app.settings';
import { AppInterceptor } from './theme/utils/app-interceptor';


import { PagesComponent } from './pages/pages.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { Toolbar1Component } from './theme/components/toolbar1/toolbar1.component';
import { Toolbar2Component } from './theme/components/toolbar2/toolbar2.component';
import { UserMenuComponent } from './theme/components/user-menu/user-menu.component';
import { CurrencyComponent } from './theme/components/currency/currency.component';
import { LangComponent } from './theme/components/lang/lang.component';
import { SocialIconsComponent } from './theme/components/social-icons/social-icons.component';
import { ContactsComponent } from './theme/components/contacts/contacts.component';
import { HorizontalMenuComponent } from './theme/components/menu/horizontal-menu/horizontal-menu.component';
import { VerticalMenuComponent } from './theme/components/menu/vertical-menu/vertical-menu.component';
import { FooterComponent } from './theme/components/footer/footer.component';
import { LockScreenComponent } from './pages/lock-screen/lock-screen.component';
import { ResetpasswordComponent } from './pages/resetpassword/resetpassword.component';
import { CreditCardDetailsComponent } from './pages/credit-card-details/credit-card-details.component';
import { AdminComponent } from './pages/admin/admin.component';
import { ChangePasswordComponent } from './pages/resetpassword/change-password/change-password.component';
// import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import * as $ from "jquery";
import { TermsComponent } from './pages/terms/terms.component';
import { MortgageCalculatorComponent } from './pages/tools/mortgage-calculator/mortgage-calculator.component';
import { PropertyRecordsComponent } from './pages/tools/property-records/property-records.component';
import { DemographicsComponent } from './pages/tools/demographics/demographics.component';
import { ChartsModule } from 'ng2-charts';
import { DateWheelComponent } from './pages/tools/date-wheel/date-wheel.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
@NgModule({
    declarations: [
        AppComponent,
        PagesComponent,
        NotFoundComponent,
        UserMenuComponent,
        CurrencyComponent,
        LangComponent,
        SocialIconsComponent,
        ContactsComponent,
        Toolbar1Component,
        Toolbar2Component,
        HorizontalMenuComponent,
        VerticalMenuComponent,
        FooterComponent,
        LockScreenComponent,
        ResetpasswordComponent,
        CreditCardDetailsComponent,
        AdminComponent,
        ChangePasswordComponent,
        TermsComponent,
        MortgageCalculatorComponent,
        DemographicsComponent,
        PropertyRecordsComponent,
        DateWheelComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        HttpClientModule,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyAmPhAcYAkkpG7_59KKJ6ssn3UZBLC0hlg',
            libraries: ["places"]
        }),
        EmbedVideo.forRoot(),
        NgProgressModule,
        NgProgressHttpModule,
        InputFileModule.forRoot(config),
        AppRouting,
        SharedModule,
        ChartsModule
    ],
    providers: [
        AppSettings,
        { provide: OverlayContainer, useClass: CustomOverlayContainer },
        { provide: HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true }
        ],
    bootstrap: [AppComponent]
})
export class AppModule { }
