import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { CreditCard } from 'src/app/app.models';
import { Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { PagesService } from '../pages.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { emailValidator } from 'src/app/theme/utils/app-validators';
import { MatDialog } from '@angular/material/dialog';
import { CardPaymentConfirmationModalComponent } from './card-payment-confirmation-modal/card-payment-confirmation-modal.component';
declare var Stripe: any;
@Component({
  selector: 'app-credit-card-details',
  templateUrl: './credit-card-details.component.html',
  styleUrls: ['./credit-card-details.component.scss']
})

export class CreditCardDetailsComponent implements OnInit {
  // public registerForm: FormGroup;
  checkoutForm = {
    noFirstName: false,
    noEmail: false,
    noAddress: false
  }
  firstName = ''
  email = ''
  address = ''
  paymentId = ''
  submitted = false;
  formProcess = false;
  cards = [];
  submit = false;
  message;
  cardElement: any;
  cardForPay: any;

  public customStripeForm: FormGroup;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  selectedPlan: any;

  constructor(public appServices: AppService, public router: Router, public snackBar: MatSnackBar, private fb: FormBuilder, private pageService: PagesService, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.pageService.currentPaymentId.subscribe(item => {
      this.selectedPlan = item;
      this.paymentId = this.selectedPlan._id;
    });
    this.getAllCards()
    // this.loadStripe();
    // const stripe = Stripe('sk_test_51HlwxiCwDxp60yBLohHkj8PhNmcxnQEFoHkwNjxWZWkDZiYQqAtgDsf9CotFiZlTrvDyf87ZNJ1HwGQjVzhQsXgQ00rs6yzmqr');
    // const stripe = Stripe('pk_test_51HlwxiCwDxp60yBLTBR1XWZBKeuWuvBuGBrY9r0PydsrcKUzIR6EOsXVl4yNKjxF04hw29bdSjT5lnODH495cMyj006PnovEw2')
    const stripe = Stripe('pk_live_51HlwxiCwDxp60yBLLIt1VI0C8YdrDprGJXbNQA0mwlcs8GmB9N2yP52C6oOsjQvqjFNirGRs9cIEMRYEG34bYgQH00A8S89Ncl');
    const elements = stripe.elements();
    const style = {
      base: {
        'lineHeight': '1.35',
        // 'fontSize': '1.11rem',
        'color': '#495057',
        'fontFamily': 'apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif'
      }
    };
    this.cardElement = elements.create('card', {
      'style': style
    });
    this.cardElement.mount('#card-element');

    this.cardElement.addEventListener('change', event => {
      const displayError = document.getElementById('card-errors');
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = '';
      }
    });
    const paymentForm = document.getElementById('payment-form');
    paymentForm.addEventListener('submit', event => {
      event.preventDefault();
      if (this.customStripeForm.controls.email.hasError('invalidEmail')) {
        this.snackBar.open('Please enter valid email address.', '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
        return;
      }
      if (this.customStripeForm.invalid) {
        this.snackBar.open('Please enter all the details to continue.', '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
        return;
      }
      stripe.createToken(this.cardElement).then(result => {

        // let stripeCard = document.getElementById('card-element');
        // stripeCard.textContent = '';
        // this.cards.push(result.token.card);
        if (result.error) {
          const errorElement = document.getElementById('card-errors');
          errorElement.textContent = result.error.message;
        } else {
          // At this point, you should send the token ID
          // to your server so it can attach
          // the payment source to a customer
          // this.cards.push(result.token.card);
          this.checkoutPayment(result.token);
        }
      });
    });


    this.customStripeForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', Validators.compose([Validators.required, emailValidator])],
      address: ['', Validators.required],
    });
  }

  checked(card) {
    const sendData = {
      cardId: card.cardId || card.id
    }
    this.pageService.makeCardDefalut(sendData).then((res: any) => {
      if (res.success) {
        this.snackBar.open(res.message, '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
        this.getAllCards()
      } else {
        this.snackBar.open(res.message, '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
      }
    })
  }

  getAllCards() {
    this.pageService.getAllCards().then((res: any) => {
      if (res.success && res.cards && res.cards.allCards) {
        this.cards = res.cards.allCards
      }
    })
  }

  payNow(card) {
    if (!this.paymentId) {
      this.snackBar.open('Please Select a Plan', '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
      return;
    }
    const dialogRef = this.dialog.open(CardPaymentConfirmationModalComponent, {
      width: '500px',
      height: '250px',
      data: card,
    });
    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        this.addSubscriber(this.selectedPlan);
        let sendData = {
          email: result.email,
          name: result.name,
          address: result.address,
          plan_id: this.paymentId,
          card: result.cardDetails,
          cardId: result.cardId,
          customer_Id: result.customer_Id,
          default: false
        }
        this.payAmount(sendData)
      }
    });
  }

  checkoutPayment(token) {
    if (!this.paymentId) {
      this.snackBar.open('Please Select a Plan', '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
      return;
    }
    this.addSubscriber(this.selectedPlan)
    let sendData = {
      email: this.customStripeForm.value.email,
      name: this.customStripeForm.value.name,
      address: this.customStripeForm.value.address,
      stripeToken: token.id,
      card: token.card.last4,
      cardId: token.card.id,
      plan_id: this.paymentId,
      default: false
    }
    this.payAmount(sendData)
  }

  payAmount(sendData) {
    this.pageService.checkoutPayment(sendData).then((res: any) => {
      if (res.success) {
        this.snackBar.open(res.message, '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
        this.pageService.setUserType("subscriber");
        localStorage.setItem('userType', "subscriber");
        localStorage.setItem('paymentStatus', 'paymentDone');
        this.customStripeForm.reset();
        this.cardElement.clear('#card-element');
        this.router.navigate(['/pricing'])
        // this.getAllCards();
      } else {
        this.snackBar.open(res.message, '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
      }
    })
  }

  addSubscriber(item){
    const sendData = {
      plan_Id: item._id,
    }
    if (item['duration'] == 'mo') {
      sendData['monthly'] = true
      sendData['yearly'] = false
    }
    else {
      sendData['monthly'] = false
      sendData['yearly'] = true
    }
    this.pageService.addSubscriber(sendData).then((res: any) => {
      if (res.success) {
        let status = localStorage.getItem('paymentStatus')
        if(status == "paymentNotDone"){
          this.pageService.setUserType("subscriber2");
          localStorage.setItem('userType',"subscriber2");
        }
      } else {
      }
    })
  }

  public remove(card) {
    const sendData = {
      cardId: card.cardId || card.id
    }
    this.pageService.deleteCard(sendData).then((res: any) => {
      if (res.success) {
        this.snackBar.open(res.message, '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
        this.getAllCards()
      } else {
        this.snackBar.open(res.message, '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
      }
    })
  }
}

