import { Component, OnInit } from "@angular/core";
import { range } from "rxjs";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
    selector: "app-mortgage-calculator",
    templateUrl: "./mortgage-calculator.component.html",
    styleUrls: ["./mortgage-calculator.component.scss"],
})
export class MortgageCalculatorComponent implements OnInit {
    amort = ["year", "month", "none"];
    public selectedAmort;
    public month = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    public years = new Array;
    public mortgageForm: FormGroup;

    constructor(
        private formBuilder: FormBuilder, public snackBar: MatSnackBar
        ) { }

    ngOnInit() {
        this.mortgageForm = this.formBuilder.group({
            purchasePrice: ['', Validators.required],
            downPay: ['', Validators.required],
            mortgageTerm: ['', Validators.required],
            interestRate: ['', Validators.required],
            propertyTax: ['', Validators.required],
            propertyInsu: ['', Validators.required],
            pmi: ['', Validators.required],
            firstPayMonth: ['', Validators.required],
            firstPayYear: ['', Validators.required],
        })
        this.populateYears();
        }

    populateYears() {
        for (var i = 1990; i <= 2099; i++) {
            this.years.push(i);
        }
    }

    calculateForm(){
        if(this.mortgageForm.invalid){
            this.snackBar.open('Please Enter all Fields','x', {panelClass:'success', verticalPosition:'top', duration: 3000 })
            return false
        }
    }

}
