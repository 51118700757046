import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Property, Location, Country } from './app.models';
import { AppSettings } from './app.settings';
import { environment } from 'src/environments/environment';

export class Data {
    constructor(public properties: Property[],
        public compareList: Property[],
        public favorites: Property[],
        public locations: Location[],
        public country: Country[]) { }
}

@Injectable({
    providedIn: 'root'
})
export class AppService {
    public Data = new Data(
        [], // properties
        [], // compareList
        [], // favorites
        [],  // locations
        [] // country
    )
    public url = "assets/data/";
    public apiKey = 'AIzaSyAmPhAcYAkkpG7_59KKJ6ssn3UZBLC0hlg';
    backendUrl = environment.backendUrl;
    public geoUrl = 'https://maps.googleapis.com/maps/api/geocode/json?latlng='

    propertyRecords = {
        lat: null,
        long: null
    }

    constructor(
        public http: HttpClient,
        private bottomSheet: MatBottomSheet,
        private snackBar: MatSnackBar,
        public appSettings: AppSettings,
        ) { }

    public getProperties(): Observable<Property[]> {
        return this.http.get<Property[]>(this.url + 'properties.json');
    }

    // public getPropertyById(id): Observable<Property> {
    //     return this.http.get<Property>(this.url + 'property-' + id + '.json');
    // }
    public getPropertyIdToUsers(id,page,limit) {
        return this.http.get('https://finddirt.com:3000/api/subscriber/property-interasted-users/' + id + '/' + page + '/' + limit)
    }

    public getFeaturedProperties(): Observable<Property[]> {
        return this.http.get<Property[]>(this.url + 'featured-properties.json');
    }

    public getRelatedProperties(): Observable<Property[]> {
        return this.http.get<Property[]>(this.url + 'related-properties.json');
    }

    public getPropertiesByAgentId(agentId): Observable<Property[]> {
        return this.http.get<Property[]>(this.url + 'properties-agentid-' + agentId + '.json');
    }

    public getLocations(): Observable<Location[]> {
        return this.http.get<Location[]>(this.url + 'locations.json');
    }

    public getCountry(): Observable<Country[]> {
        return this.http.get<Country[]>(this.url + 'countryCodes.json');
    }

    public getAddress(lat = 40.714224, lng = -73.961452) {
        return this.http.get(this.geoUrl + lat + ',' + lng + '&key=' + this.apiKey);
    }

    public getLatLng(address) {
        return this.http.get('https://maps.googleapis.com/maps/api/geocode/json?key=' + this.apiKey + '&address=' + address);
    }

    public userValidation() {
        return this.http.get(this.backendUrl + '/user/velidation');
    }

    public getFullAddress(lat = 40.714224, lng = -73.961452) {
        return this.http.get(this.geoUrl + lat + ',' + lng + '&key=' + this.apiKey).subscribe(data => {
            return data['results'][0]['formatted_address'];
        });
    }

    public addToCompare(property: Property, component, direction) {
        if (!this.Data.compareList.filter(item => item.id == property.id)[0]) {
            this.Data.compareList.push(property);
            this.bottomSheet.open(component, {
                direction: direction
            }).afterDismissed().subscribe(isRedirect => {
                if (isRedirect) {
                    window.scrollTo(0, 0);
                }
            });
        }
    }

    public addToFavorites(property: Property, direction) {
        if (!this.Data.favorites.filter(item => item.id == property.id)[0]) {
            this.Data.favorites.push(property);
            this.snackBar.open('The property "' + property.title + '" has been added to favorites.', '×', {
                verticalPosition: 'top',
                duration: 3000,
                direction: direction
            });
        }
    }

    public getPropertyTypes() {
        return [
            { id: 1, name: 'Healthcare' },
            { id: 2, name: 'Hospitality' },
            { id: 3, name: 'Office' },
            { id: 4, name: 'Land' },
            { id: 5, name: 'Multifamily' },
            { id: 6, name: 'Retail' },
            { id: 7, name: 'Restaurant' },
            { id: 8, name: 'Shopping Center' },
            { id: 9, name: 'Specialty Residential' }
        ];
    }
   
    public getCreditCard() {
        return [
            { id: 1, name: 'rahul', number: 5665566 },
            { id: 2, name: 'rohit', number: 5161651651 },
            { id: 3, name: 'Office', number: 1651616 },
            { id: 4, name: 'Land', number: 565561 },
            { id: 5, name: 'Multifamily', number: 16516516 },
            { id: 6, name: 'Retail', number: 1161516 },
            { id: 7, name: 'Restaurant', number: 16161 },
            { id: 8, name: 'Shopping Center', number: 1616516 },
            { id: 9, name: 'Specialty Residential', number: 61616 }
        ];
    }
    public getPropertyCategories() {
        return [
            { id: 1, name: 'For Sale', value: 'Sale' },
            { id: 2, name: 'For Lease', value: 'lease' },
            { id: 3, name: 'Businesses For Sale', value: 'Business' }
        ];
    }

    public getForsale() {
        return [
            { id: 1, name: 'Healthcare' },
            { id: 2, name: 'Hospitality' },
            { id: 3, name: 'Office' },
            { id: 4, name: 'Land' },
            { id: 5, name: 'Multifamily' },
            { id: 6, name: 'Retail' },
            { id: 7, name: 'Restaurant' },
            { id: 8, name: 'Shopping Center' },
            { id: 9, name: 'Specialty Residential' }
        ];
    }
    public getForbusiness() {
        return [
            { id: 1, name: 'Restaurants & Food' },
            { id: 2, name: 'Automotive' },
            { id: 3, name: 'Marine' },
            { id: 4, name: 'Building & Construction' },
            { id: 5, name: ' Online & Technology' },
            { id: 6, name: 'Retail' },
            { id: 7, name: 'Service Industry' },
            { id: 8, name: 'Transportation & Storage' },
            { id: 9, name: 'Wholesale & Distributors' }
        ];
    }
    public getForlease() {
        return [
            { id: 1, name: 'Office' },
            { id: 2, name: 'Flex' },
            { id: 3, name: 'Coworking' },
            { id: 4, name: 'Industrial' },
            { id: 5, name: 'Retail' },
            { id: 6, name: 'Restaurant' },
            { id: 7, name: 'Flex' },
            { id: 8, name: 'Medical' },
            { id: 9, name: 'Land' }
        ];
    }

    public getPropertyCategory() {
        return [
            { id: 1, name: 'For Sale' },
            { id: 2, name: 'For Rent' },
            { id: 3, name: 'Open House' },
            { id: 4, name: 'No Fees' },
            { id: 5, name: 'Hot Offer' },
            { id: 6, name: 'Sold' }
        ]
    }

    public getCities() {
        return [
            { id: 1, name: 'New York' },
            { id: 2, name: 'Chicago' },
            { id: 3, name: 'Los Angeles' },
            { id: 4, name: 'Seattle' }
        ]
    }

    public getNeighborhoods() {
        return [
            { id: 1, name: 'Astoria', cityId: 1 },
            { id: 2, name: 'Midtown', cityId: 1 },
            { id: 3, name: 'Chinatown', cityId: 1 },
            { id: 4, name: 'Austin', cityId: 2 },
            { id: 5, name: 'Englewood', cityId: 2 },
            { id: 6, name: 'Riverdale', cityId: 2 },
            { id: 7, name: 'Hollywood', cityId: 3 },
            { id: 8, name: 'Sherman Oaks', cityId: 3 },
            { id: 9, name: 'Highland Park', cityId: 3 },
            { id: 10, name: 'Belltown', cityId: 4 },
            { id: 11, name: 'Queen Anne', cityId: 4 },
            { id: 12, name: 'Green Lake', cityId: 4 }
        ]
    }

    public getStreets() {
        return [
            { id: 1, name: 'Astoria Street #1', cityId: 1, neighborhoodId: 1 },
            { id: 2, name: 'Astoria Street #2', cityId: 1, neighborhoodId: 1 },
            { id: 3, name: 'Midtown Street #1', cityId: 1, neighborhoodId: 2 },
            { id: 4, name: 'Midtown Street #2', cityId: 1, neighborhoodId: 2 },
            { id: 5, name: 'Chinatown Street #1', cityId: 1, neighborhoodId: 3 },
            { id: 6, name: 'Chinatown Street #2', cityId: 1, neighborhoodId: 3 },
            { id: 7, name: 'Austin Street #1', cityId: 2, neighborhoodId: 4 },
            { id: 8, name: 'Austin Street #2', cityId: 2, neighborhoodId: 4 },
            { id: 9, name: 'Englewood Street #1', cityId: 2, neighborhoodId: 5 },
            { id: 10, name: 'Englewood Street #2', cityId: 2, neighborhoodId: 5 },
            { id: 11, name: 'Riverdale Street #1', cityId: 2, neighborhoodId: 6 },
            { id: 12, name: 'Riverdale Street #2', cityId: 2, neighborhoodId: 6 },
            { id: 13, name: 'Hollywood Street #1', cityId: 3, neighborhoodId: 7 },
            { id: 14, name: 'Hollywood Street #2', cityId: 3, neighborhoodId: 7 },
            { id: 15, name: 'Sherman Oaks Street #1', cityId: 3, neighborhoodId: 8 },
            { id: 16, name: 'Sherman Oaks Street #2', cityId: 3, neighborhoodId: 8 },
            { id: 17, name: 'Highland Park Street #1', cityId: 3, neighborhoodId: 9 },
            { id: 18, name: 'Highland Park Street #2', cityId: 3, neighborhoodId: 9 },
            { id: 19, name: 'Belltown Street #1', cityId: 4, neighborhoodId: 10 },
            { id: 20, name: 'Belltown Street #2', cityId: 4, neighborhoodId: 10 },
            { id: 21, name: 'Queen Anne Street #1', cityId: 4, neighborhoodId: 11 },
            { id: 22, name: 'Queen Anne Street #2', cityId: 4, neighborhoodId: 11 },
            { id: 23, name: 'Green Lake Street #1', cityId: 4, neighborhoodId: 12 },
            { id: 24, name: 'Green Lake Street #2', cityId: 4, neighborhoodId: 12 }
        ]
    }

    public getFeatures() {
        return [
            { id: 1, name: 'Air Conditioning', selected: false },
            // { id: 2, name: 'Barbeque', selected: false },
            // { id: 3, name: 'Dryer', selected: false },
            // { id: 4, name: 'Microwave', selected: false },
            { id: 2, name: 'Refrigerator', selected: false },
            { id: 3, name: 'TV Cable', selected: false },
            { id: 4, name: 'Sauna', selected: false },
            { id: 5, name: 'WiFi', selected: false },
            { id: 6, name: 'Fireplace', selected: false },
            { id: 7, name: 'Swimming Pool', selected: false },
            { id: 8, name: 'Gym', selected: false },
            { id: 9, name: 'Atrium', selected: false },
            // { id: 13, name: 'Autotmatic Blinds ', selected: false },
            { id: 10, name: 'Balcony', selected: false },
            // { id: 15, name: 'Banking', selected: false },
            { id: 11, name: 'Bicycle Storage', selected: false },
            // { id: 17, name: 'Bio-Tech/Lab', selected: false },
            { id: 12, name: 'Bus Line', selected: false },
            { id: 13, name: 'Car Charging Station', selected: false },
            { id: 14, name: 'Central Heating', selected: false },
            { id: 15, name: 'Commuter Raill', selected: false },
            { id: 16, name: 'Concierge', selected: false },
            { id: 17, name: 'Conference Room', selected: false },
            { id: 18, name: 'Controlled Accessue', selected: false },
            // { id: 25, name: 'Convenience Store', selected: false },
            { id: 19, name: 'Courtyard', selected: false },
            { id: 20, name: 'Daycare', selected: false },
            { id: 21, name: 'Direct Elevator Exposure', selected: false },
            // { id: 29, name: 'Drop Ceiling', selected: false },
            // { id: 30, name: 'Dry Cleaner', selected: false },
            { id: 22, name: 'Fenced Lot', selected: false },
            { id: 23, name: 'Fire Pits', selected: false },
            { id: 24, name: 'Roof Terrace', selected: false },
            { id: 25, name: 'Fitness Center', selected: false },
            { id: 26, name: 'Food Court ', selected: false },
            { id: 27, name: 'Food Service', selected: false },
            { id: 28, name: 'Fully Carpeted', selected: false },
            { id: 29, name: 'Golf Course', selected: false },
            { id: 30, name: 'Hardwood Floors', selected: false },
            { id: 31, name: 'High Ceilings', selected: false },
            { id: 32, name: 'Kitchen', selected: false },
            { id: 33, name: 'Metro/Subway', selected: false },
            { id: 34, name: 'Monument Signage', selected: false },
            { id: 35, name: 'Natural Light', selected: false },
            { id: 36, name: 'Outdoor Seating', selected: false },
            { id: 37, name: 'Partitioned Offices', selected: false },
            { id: 38, name: 'Plug & Play', selected: false },
            // { id: 48, name: 'Pond', selected: false },
            { id: 39, name: 'Property Manager on Site', selected: false },
            // { id: 50, name: 'Raised Floor', selected: false },
            { id: 40, name: 'Reception', selected: false },
            { id: 41, name: 'Recessed Lighting', selected: false },
            { id: 42, name: 'Restaurant', selected: false },
            { id: 43, name: 'Secure Storage', selected: false },
            { id: 44, name: 'Security Systems', selected: false },
            { id: 45, name: 'Showers', selected: false },
            { id: 46, name: 'Signage ', selected: false },
            { id: 47, name: 'Sky Terrace', selected: false },
            { id: 48, name: 'Skylights', selected: false },
            { id: 49, name: 'Skyway', selected: false },
            { id: 50, name: 'Storage Space', selected: false },
            { id: 51, name: 'Waterfront', selected: false },
        ]
    }


    public getHomeCarouselSlides() {
        return this.http.get<any[]>(this.url + 'slides.json');
    }


    public filterData(data, params: any, sort?, page?, perPage?) {

        if (params) {

            if (params.propertyType) {
                data = data.filter(property => property.propertyType == params.propertyType.name)
            }

            if (params.propertyStatus && params.propertyStatus.length) {
                let statuses = [];
                params.propertyStatus.forEach(status => { statuses.push(status.name) });
                let properties = [];
                data.filter(property =>
                    property.propertyStatus.forEach(status => {
                        if (statuses.indexOf(status) > -1) {
                            if (!properties.includes(property)) {
                                properties.push(property);
                            }
                        }
                    })
                );
                data = properties;
            }

            if (params.price) {
                if (this.appSettings.settings.currency == 'USD') {
                    if (params.price.from) {
                        data = data.filter(property => {
                            if (property.priceDollar.sale && property.priceDollar.sale >= params.price.from) {
                                return true;
                            }
                            if (property.priceDollar.rent && property.priceDollar.rent >= params.price.from) {
                                return true;
                            }
                            return false;
                        });
                    }
                    if (params.price.to) {
                        data = data.filter(property => {
                            if (property.priceDollar.sale && property.priceDollar.sale <= params.price.to) {
                                return true;
                            }
                            if (property.priceDollar.rent && property.priceDollar.rent <= params.price.to) {
                                return true;
                            }
                            return false;
                        });
                    }
                }
                if (this.appSettings.settings.currency == 'EUR') {
                    if (params.price.from) {
                        data = data.filter(property => {
                            if (property.priceEuro.sale && property.priceEuro.sale >= params.price.from) {
                                return true;
                            }
                            if (property.priceEuro.rent && property.priceEuro.rent >= params.price.from) {
                                return true;
                            }
                            return false;
                        });

                    }
                    if (params.price.to) {
                        data = data.filter(property => {
                            if (property.priceEuro.sale && property.priceEuro.sale <= params.price.to) {
                                return true;
                            }
                            if (property.priceEuro.rent && property.priceEuro.rent <= params.price.to) {
                                return true;
                            }
                            return false;
                        });
                    }
                }
            }

            if (params.city) {
                data = data.filter(property => property.city == params.city.name)
            }

            if (params.zipCode) {
                data = data.filter(property => property.zipCode == params.zipCode)
            }

            if (params.neighborhood && params.neighborhood.length) {
                let neighborhoods = [];
                params.neighborhood.forEach(item => { neighborhoods.push(item.name) });
                let properties = [];
                data.filter(property =>
                    property.neighborhood.forEach(item => {
                        if (neighborhoods.indexOf(item) > -1) {
                            if (!properties.includes(property)) {
                                properties.push(property);
                            }
                        }
                    })
                );
                data = properties;
            }

            if (params.street && params.street.length) {
                let streets = [];
                params.street.forEach(item => { streets.push(item.name) });
                let properties = [];
                data.filter(property =>
                    property.street.forEach(item => {
                        if (streets.indexOf(item) > -1) {
                            if (!properties.includes(property)) {
                                properties.push(property);
                            }
                        }
                    })
                );
                data = properties;
            }

            if (params.bedrooms) {
                if (params.bedrooms.from) {
                    data = data.filter(property => property.bedrooms >= params.bedrooms.from)
                }
                if (params.bedrooms.to) {
                    data = data.filter(property => property.bedrooms <= params.bedrooms.to)
                }
            }

            if (params.bathrooms) {
                if (params.bathrooms.from) {
                    data = data.filter(property => property.bathrooms >= params.bathrooms.from)
                }
                if (params.bathrooms.to) {
                    data = data.filter(property => property.bathrooms <= params.bathrooms.to)
                }
            }

            // if(params.apn){
            //   if(params.apn.from){
            //     data = data.filter(property => property.apn >= params.apn.from)
            //   }
            //   if(params.apn.to){
            //     data = data.filter(property => property.apn <= params.apn.to)
            //   }
            // } 

            // if(params.elevators){
            //   if(params.elevators.from){
            //     data = data.filter(property => property.elevators >= params.elevators.from)
            //   }
            //   if(params.elevators.to){
            //     data = data.filter(property => property.elevators <= params.elevators.to)
            //   }
            // } 

            // if(params.secondaryType){
            //   if(params.secondaryType.from){
            //     data = data.filter(property => property.secondaryType >= params.secondaryType.from)
            //   }
            //   if(params.secondaryType.to){
            //     data = data.filter(property => property.secondaryType <= params.secondaryType.to)
            //   }
            // } 

            // if(params.construction){
            //   if(params.construction.from){
            //     data = data.filter(property => property.construction >= params.construction.from)
            //   }
            //   if(params.construction.to){
            //     data = data.filter(property => property.construction <= params.construction.to)
            //   }
            // } 

            // if(params.parking){
            //   if(params.parking.from){
            //     data = data.filter(property => property.parking >= params.parking.from)
            //   }
            //   if(params.parking.to){
            //     data = data.filter(property => property.parking <= params.parking.to)
            //   }
            // } 

            // if(params.sprinklers){
            //   if(params.sprinklers.from){
            //     data = data.filter(property => property.sprinklers >= params.sprinklers.from)
            //   }
            //   if(params.sprinklers.to){
            //     data = data.filter(property => property.sprinklers <= params.sprinklers.to)
            //   }
            // }

            if (params.garages) {
                if (params.garages.from) {
                    data = data.filter(property => property.garages >= params.garages.from)
                }
                if (params.garages.to) {
                    data = data.filter(property => property.garages <= params.garages.to)
                }
            }

            if (params.area) {
                if (params.area.from) {
                    data = data.filter(property => property.area.value >= params.area.from)
                }
                if (params.area.to) {
                    data = data.filter(property => property.area.value <= params.area.to)
                }
            }

            if (params.yearBuilt) {
                if (params.yearBuilt.from) {
                    data = data.filter(property => property.yearBuilt >= params.yearBuilt.from)
                }
                if (params.yearBuilt.to) {
                    data = data.filter(property => property.yearBuilt <= params.yearBuilt.to)
                }
            }

            if (params.features) {
                let arr = [];
                params.features.forEach(feature => {
                    if (feature.selected)
                        arr.push(feature.name);
                });
                if (arr.length > 0) {
                    let properties = [];
                    data.filter(property =>
                        property.features.forEach(feature => {
                            if (arr.indexOf(feature) > -1) {
                                if (!properties.includes(property)) {
                                    properties.push(property);
                                }
                            }
                        })
                    );
                    data = properties;
                }

            }

        }


        //for show more properties mock data 
        for (var index = 0; index < 2; index++) {
            data = data.concat(data);
        }

        this.sortData(sort, data);
        return this.paginator(data, page, perPage)
    }

    public sortData(sort, data) {
        if (sort) {
            switch (sort) {
                case 'Newest':
                    data = data.sort((a, b) => { return <any>new Date(b.published) - <any>new Date(a.published) });
                    break;
                case 'Oldest':
                    data = data.sort((a, b) => { return <any>new Date(a.published) - <any>new Date(b.published) });
                    break;
                case 'Popular':
                    data = data.sort((a, b) => {
                        if (a.ratingsValue / a.ratingsCount < b.ratingsValue / b.ratingsCount) {
                            return 1;
                        }
                        if (a.ratingsValue / a.ratingsCount > b.ratingsValue / b.ratingsCount) {
                            return -1;
                        }
                        return 0;
                    });
                    break;
                case 'Price (Low to High)':
                    if (this.appSettings.settings.currency == 'USD') {
                        data = data.sort((a, b) => {
                            if ((a.priceDollar.sale || a.priceDollar.rent) > (b.priceDollar.sale || b.priceDollar.rent)) {
                                return 1;
                            }
                            if ((a.priceDollar.sale || a.priceDollar.rent) < (b.priceDollar.sale || b.priceDollar.rent)) {
                                return -1;
                            }
                            return 0;
                        })
                    }
                    if (this.appSettings.settings.currency == 'EUR') {
                        data = data.sort((a, b) => {
                            if ((a.priceEuro.sale || a.priceEuro.rent) > (b.priceEuro.sale || b.v.rent)) {
                                return 1;
                            }
                            if ((a.priceEuro.sale || a.priceEuro.rent) < (b.priceEuro.sale || b.priceEuro.rent)) {
                                return -1;
                            }
                            return 0;
                        })
                    }
                    break;
                case 'Price (High to Low)':
                    if (this.appSettings.settings.currency == 'USD') {
                        data = data.sort((a, b) => {
                            if ((a.priceDollar.sale || a.priceDollar.rent) < (b.priceDollar.sale || b.priceDollar.rent)) {
                                return 1;
                            }
                            if ((a.priceDollar.sale || a.priceDollar.rent) > (b.priceDollar.sale || b.priceDollar.rent)) {
                                return -1;
                            }
                            return 0;
                        })
                    }
                    if (this.appSettings.settings.currency == 'EUR') {
                        data = data.sort((a, b) => {
                            if ((a.priceEuro.sale || a.priceEuro.rent) < (b.priceEuro.sale || b.v.rent)) {
                                return 1;
                            }
                            if ((a.priceEuro.sale || a.priceEuro.rent) > (b.priceEuro.sale || b.priceEuro.rent)) {
                                return -1;
                            }
                            return 0;
                        })
                    }
                    break;
                default:
                    break;
            }
        }
        return data;
    }

    public paginator(items, page?, perPage?) {
        var page = page || 1,
            perPage = perPage || 4,
            offset = (page - 1) * perPage,
            paginatedItems = items.slice(offset).slice(0, perPage),
            totalPages = Math.ceil(items.length / perPage);
        return {
            data: paginatedItems,
            pagination: {
                page: page,
                perPage: perPage,
                prePage: page - 1 ? page - 1 : null,
                nextPage: (totalPages > page) ? page + 1 : null,
                total: items.length,
                totalPages: totalPages,
            }
        };
    }



    public getTestimonials() {
        return [
            {
                text: 'Donec molestie turpis ut mollis efficitur. Nam fringilla libero vel dictum vulputate. In malesuada, ligula non ornare consequat, augue nibh luctus nisl, et lobortis justo ipsum nec velit. Praesent lacinia quam ut nulla gravida, at viverra libero euismod. Sed tincidunt tempus augue vitae malesuada. Vestibulum eu lectus nisi. Aliquam erat volutpat.',
                author: 'Mr. Adam Sandler',
                position: 'General Director',
                image: 'assets/images/profile/adam.jpg'
            },
            {
                text: 'Donec molestie turpis ut mollis efficitur. Nam fringilla libero vel dictum vulputate. In malesuada, ligula non ornare consequat, augue nibh luctus nisl, et lobortis justo ipsum nec velit. Praesent lacinia quam ut nulla gravida, at viverra libero euismod. Sed tincidunt tempus augue vitae malesuada. Vestibulum eu lectus nisi. Aliquam erat volutpat.',
                author: 'Ashley Ahlberg',
                position: 'Housewife',
                image: 'assets/images/profile/ashley.jpg'
            },
            {
                text: 'Donec molestie turpis ut mollis efficitur. Nam fringilla libero vel dictum vulputate. In malesuada, ligula non ornare consequat, augue nibh luctus nisl, et lobortis justo ipsum nec velit. Praesent lacinia quam ut nulla gravida, at viverra libero euismod. Sed tincidunt tempus augue vitae malesuada. Vestibulum eu lectus nisi. Aliquam erat volutpat.',
                author: 'Bruno Vespa',
                position: 'Blogger',
                image: 'assets/images/profile/bruno.jpg'
            },
            {
                text: 'Donec molestie turpis ut mollis efficitur. Nam fringilla libero vel dictum vulputate. In malesuada, ligula non ornare consequat, augue nibh luctus nisl, et lobortis justo ipsum nec velit. Praesent lacinia quam ut nulla gravida, at viverra libero euismod. Sed tincidunt tempus augue vitae malesuada. Vestibulum eu lectus nisi. Aliquam erat volutpat.',
                author: 'Mrs. Julia Aniston',
                position: 'Marketing Manager',
                image: 'assets/images/profile/julia.jpg'
            }
        ];
    }

    public getAgents() {
        return [
            {
                id: 1,
                fullName: 'Lusia Manuel',
                desc: 'Phasellus sed metus leo. Donec laoreet, lacus ut suscipit convallis, erat enim eleifend nulla, at sagittis enim urna et lacus.',
                organization: 'FindDirt',
                email: 'lusia.m@housekey.com',
                phone: '(224) 267-1346',
                social: {
                    facebook: 'lusia',
                    twitter: 'lusia',
                    linkedin: 'lusia',
                    instagram: 'lusia',
                    website: 'https://lusia.manuel.com'
                },
                ratingsCount: 6,
                ratingsValue: 480,
                image: 'assets/images/agents/a-1.jpg'
            },
            {
                id: 2,
                fullName: 'Andy Warhol',
                desc: 'Phasellus sed metus leo. Donec laoreet, lacus ut suscipit convallis, erat enim eleifend nulla, at sagittis enim urna et lacus.',
                organization: 'FindDirt',
                email: 'andy.w@housekey.com',
                phone: '(212) 457-2308',
                social: {
                    facebook: '',
                    twitter: '',
                    linkedin: '',
                    instagram: '',
                    website: 'https://andy.warhol.com'
                },
                ratingsCount: 4,
                ratingsValue: 400,
                image: 'assets/images/agents/a-2.jpg'
            },
            {
                id: 3,
                fullName: 'Tereza Stiles',
                desc: 'Phasellus sed metus leo. Donec laoreet, lacus ut suscipit convallis, erat enim eleifend nulla, at sagittis enim urna et lacus.',
                organization: 'FindDirt',
                email: 'tereza.s@housekey.com',
                phone: '(214) 617-2614',
                social: {
                    facebook: '',
                    twitter: '',
                    linkedin: '',
                    instagram: '',
                    website: 'https://tereza.stiles.com'
                },
                ratingsCount: 4,
                ratingsValue: 380,
                image: 'assets/images/agents/a-3.jpg'
            },
            {
                id: 4,
                fullName: 'Michael Blair',
                desc: 'Phasellus sed metus leo. Donec laoreet, lacus ut suscipit convallis, erat enim eleifend nulla, at sagittis enim urna et lacus.',
                organization: 'FindDirt',
                email: 'michael.b@housekey.com',
                phone: '(267) 388-1637',
                social: {
                    facebook: '',
                    twitter: '',
                    linkedin: '',
                    instagram: '',
                    website: 'https://michael.blair.com'
                },
                ratingsCount: 6,
                ratingsValue: 480,
                image: 'assets/images/agents/a-4.jpg'
            },
            {
                id: 5,
                fullName: 'Michelle Ormond',
                desc: 'Phasellus sed metus leo. Donec laoreet, lacus ut suscipit convallis, erat enim eleifend nulla, at sagittis enim urna et lacus.',
                organization: 'FindDirt',
                email: 'michelle.o@housekey.com',
                phone: '(267) 388-1637',
                social: {
                    facebook: '',
                    twitter: '',
                    linkedin: '',
                    instagram: '',
                    website: 'https://michelle.ormond.com'
                },
                ratingsCount: 6,
                ratingsValue: 480,
                image: 'assets/images/agents/a-5.jpg'
            }
        ];
    }

    public getClients() {
        return [
            { name: 'aloha', image: 'assets/images/clients/aloha.png' },
            { name: 'dream', image: 'assets/images/clients/dream.png' },
            { name: 'congrats', image: 'assets/images/clients/congrats.png' },
            { name: 'best', image: 'assets/images/clients/best.png' },
            { name: 'original', image: 'assets/images/clients/original.png' },
            { name: 'retro', image: 'assets/images/clients/retro.png' },
            { name: 'king', image: 'assets/images/clients/king.png' },
            { name: 'love', image: 'assets/images/clients/love.png' },
            { name: 'the', image: 'assets/images/clients/the.png' },
            { name: 'easter', image: 'assets/images/clients/easter.png' },
            { name: 'with', image: 'assets/images/clients/with.png' },
            { name: 'special', image: 'assets/images/clients/special.png' },
            { name: 'bravo', image: 'assets/images/clients/bravo.png' }
        ];
    }
    // Property Records Details

    private propertyRecordsDetailsService = new BehaviorSubject<string>('');
    currentPropertyRecordsDetailsService = this.propertyRecordsDetailsService.asObservable();

    sendPropertyDetails(data): any {
        console.log(data)
        this.propertyRecordsDetailsService.next(data);
    }
    
    getAddressFormattedData(results: any) {
        var street = "";
        var city = "";
        var state = "";
        var country = "";
        var zipcode = "";
        var stateCode = "";
        results.address_components.map((address) => {
            switch (address.types[0]) {
                case "street_number":
                    street += " " + address.long_name;
                    break;
                case "route":
                    street += " " + address.long_name;
                    break;
                case "country":
                    country = address.long_name;
                    break;
                case "postal_code":
                    zipcode = address.long_name;
                    break;
                case "administrative_area_level_1":
                    state = address.long_name;
                    stateCode = address.short_name
                    break;
                case "locality":
                    city = address.long_name;
                    break;
                default:
                        if(!city.length){
                            if(address.types[0] === "administrative_area_level_2"){
                                city = address.long_name;
                            } else if (address.types[0] === "administrative_area_level_3"){
                                city = address.long_name;
                            }
                        }
                    break;
            }
        });
        var address = {
          street: street,
          city: city,
          state: state,
          country: country,
          zipcode: zipcode,
          stateCode: stateCode
        };
        return address;
      }

      getCenterType(){
        return [
            { id: 1, name: 'Multi-Tenant' },
            { id: 2, name: 'Medical Complex' },
            { id: 3, name: 'Single Tenant' },
            { id: 4, name: 'Grocery Anchored Center' },
            { id: 5, name: 'Power Center' },
        ];
      }
      getLeaseType(){
        return [
            { id: 1, name: 'N' },
            { id: 2, name: 'NN' },
            { id: 3, name: 'NNN' },
            { id: 4, name: 'Ground Lease'},
        ];
      }
}
