import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { PagesService } from '../../pages/pages.service';
@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {
  myProperties = false;
  public psConfig: PerfectScrollbarConfigInterface = {
    wheelPropagation:true
  };
  @ViewChild('sidenav') sidenav: any;
  public sidenavOpen:boolean = true;
  public links = [ 
    { name: 'Subscriber', href: 'subscribers', icon: 'person' },  
    { name: 'User', href: 'users', icon: 'person' },
    { name: 'Blog', href: 'blog', icon: 'inbox' },
    { name: 'Plans', href: 'plans', icon: 'view_list' },
    { name: 'Properties', href: 'properties', icon: 'list_alt' },
    { name: 'Upload Traffic Data', href: 'upload-traffic-data', icon: 'traffic' },
    { name: 'Upload Comp Data', href: 'upload-comp-data',icon:'upload'},
    { name: 'Comp Data', href: 'admin-comp-data',icon:'view_list'},
    { name: 'Logout', href: '/login', icon: 'power_settings_new' },    
  ];  
  unsubscribed = {
    email:''
  }
  constructor(public router:Router,
    public pagesService: PagesService) {
   }

  ngOnInit() {
    // if (window.innerWidth < 960){
    //   this.sidenavOpen = false;
    // }

  }

  @HostListener('window:resize')
  public onWindowResize():void {
    (window.innerWidth < 960) ? this.sidenavOpen = false : this.sidenavOpen = true;
  }

  ngAfterViewInit(){
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {  
        if(window.innerWidth < 960){
          this.sidenav.close(); 
        }
      }                
    });
  } 
  public signOut(item) {
    if(item == 'Logout') {
      this.pagesService.changeMessage(false);
      this.pagesService.setUserType(this.unsubscribed);
      localStorage.clear()
    }
  

  }

}
